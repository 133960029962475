
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Grid, Dialog, TextField, AppBar, Toolbar, DialogTitle, DialogContent, DialogActions, Typography, Switch,CardMedia , ListItemAvatar , Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AdminNavbar from '../../components/AdminNavbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList'; // Importation du composant FilterListIcon
import { Chip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormLabel, RadioGroup, Radio } from '@material-ui/core';
import axiosInstance from '../../services/axiosInstance';
import AccessDeniedMessage from '../../components/AccessDeniedMessage';
import ManageIngredients from '../../components/ManageIngredients';




import DrinkSchedule from '../../components/DrinkSchedule';
import CreateContainerComponent from '../../components/CreateContainerComponentEtablissement'


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: 800,
        margin: 'auto'
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    productCard: {
        marginBottom: theme.spacing(2),
    },

    // section: {
    //     marginBottom: theme.spacing(4),
    //     padding: theme.spacing(2),
    //     border: `1px solid ${theme.palette.divider}`,
    //     borderRadius: theme.shape.borderRadius,
    // },
    header: {
        marginBottom: theme.spacing(2),
    },

    section: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)`,
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        borderRadius: theme.shape.borderRadius,
    }
}));


async function fetchEstablishment(id) {
    try {
        const res = await axiosInstance.get(`/establishments/${id}`);
        return res.data.establishment;
    } catch (err) {
        // Vérifiez si l'erreur provient d'une réponse HTTP et contient un message d'erreur
        if (err.response && err.response.data && err.response.data.error) {
            // alert(err.response.data.error); // Affiche le message d'erreur
            return false

        } else {
            alert("Une erreur est survenue lors de la récupération de l'établissement.");
        }
        console.error(err);
        return null;
    }
}

function ManageProductsPage() {
    const { id_etablissement } = useParams();
    const [establishment, setEstablishment] = useState(null);
    const [products, setProducts] = useState([]);
    const [drinks, setDrinks] = useState([]);
    const [selectedDrinkId, setSelectedDrinkId] = useState(null);
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [name_etablissement, SetNameEtablissement] = useState('')
    const [product_category, SetProduct_category] = useState('drink')

    const [forGourd, setForGourd] = useState(false);
    const [forMug, setForMug] = useState(false);
    const [forBox, setForBox] = useState(false);
    const [refusedContainers, setRefusedContainers] = useState([]);
    const [containers, setContainers] = useState([]);
    const [containers_chr, setContainers_chr] = useState([]);
    const [unitPrice, setUnitPrice] = useState('');
    const [pricingMode, setPricingMode] = useState('perLiter')
    const [pricingMode2, setPricingMode2] = useState('perLiter')

    const [unitVolume, setUnitVolume] = useState(''); // Ajout de l'état pour le volume par unité
    const [imageFile, setImageFile] = useState(null);
    const [hasAccess, sethasAccess] = useState(true);
    const [bio, setBio] = useState(false);
    const [filteredContainers, setFilteredContainers] = useState([]);
    const [isVisible, setIsVisible] = useState(true); // par défaut, visible


    const [drinkName, setDrinkName] = useState('');
    const [description, setDescription] = useState('');
    const [category_boisson, setCategory_boisson] = useState('');
    const [selectedContainerId, setSelectedContainerId] = useState('');
    const [selectedContainerId2, setSelectedContainerId2] = useState('');



    const [currentCategory, setCurrentCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [drinkSchedule, setDrinkSchedule] = useState([]);
    const [isEnterpriseCodeRequired, setIsEnterpriseCodeRequired] = useState(false);
    const [contenantRequired, setContenantRequired] = useState(false);



    const [currentIngredient, setCurrentIngredient] = useState({ name: '', description: '', brand: '', photo: null });


    const [hasAgreed, setHasAgreed] = useState(false);


    // Ajoutez des états pour le contenant
    const [containerName, setContainerName] = useState('');
    const [containerBrand, setContainerBrand] = useState('');
    const [containerVolume, setContainerVolume] = useState('');
    const [containerImage, setContainerImage] = useState(null);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedProductForEdit, setSelectedProductForEdit] = useState(null);
    const [selectedProductForEdit2, setSelectedProductForEdit2] = useState(null);
    const [categories_boisson, setCategories_boisson] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([])

    const [ingredients, setIngredients] = useState([]);
    const [selectedProductIngredients, setSelectedProductIngredients] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [ingredientForm, setIngredientForm] = useState({ id: null, name: '', brand: '', description: '', photo: null });

    // catégories

    const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
    const [categories_easydrinks, setCategories_easydrinks] = useState([]);
    const [editSelectedCategoryPath, setEditSelectedCategoryPath] = useState([]);
    const [productType, setProductType] = useState('drink');

    const [allowTransparentContainers, setAllowTransparentContainers] = useState(false);
    const [editOfferedDrinks, setEditOfferedDrinks] = useState(0);

   

    const [offeredDrinks, setOfferedDrinks] = useState(0);
    const [canBeOffered, setCanBeOffered] = useState(false);
    const [drinkcCanBeOffered, setDrinksCanBeOffered] = useState(false);

    // ManageProductsPage.js

const [depositPrice, setDepositPrice] = useState('');
const [acceptsCustomerContainer, setAcceptsCustomerContainer] = useState(false);
const [preconditionedWithDeposit, setPreconditionedWithDeposit] = useState(false);
const [isVegetarian, setIsVegetarian] = useState(false);


  


    useEffect(() => {
        // Charger les catégories principales
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://backend.csibon.app/categories_easy_drinks/categories');
                setCategories_easydrinks(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(()=>{

        if(productType === 'food' ){

            setForGourd(false)
            setForMug(false)
            setBio(false)
            setForBox(true)
            setPricingMode("perUnit")
            setUnitVolume(null)
            SetProduct_category('food')


        }else{
            setForBox(false)
            SetProduct_category('drink')

        }


    },[productType])



    const getCurrentCategories = (level) => {
        let currentCategories = categories_easydrinks;
        for (let i = 0; i < level; i++) {
            const categoryId = selectedCategoryPath[i];
            const category = currentCategories.find(cat => cat.id === categoryId);
            if (category && category.subCategories) {
                currentCategories = category.subCategories;
            } else {
                return [];
            }
        }
        return currentCategories;
    };

    const handleCategoryChange = (categoryId, level) => {
        if (categoryId === "") {
            // Si l'utilisateur choisit l'option par défaut, réduire le chemin sélectionné
            setSelectedCategoryPath(selectedCategoryPath.slice(0, level));
        } else {
            const newSelectedPath = [...selectedCategoryPath.slice(0, level), categoryId];
            setSelectedCategoryPath(newSelectedPath);
        }
    };

    const renderCategorySelect = (level) => {
        const currentCategories = getCurrentCategories(level);
        if (level !== 0 && currentCategories.length === 0) return null;

        return (
            <FormControl className={classes.formControl} key={level}>
                <InputLabel>{level === 0 ? 'Catégorie principale' : `Sous-catégorie niveau ${level}`}</InputLabel>
                <Select
                    value={selectedCategoryPath[level] || ''}
                    onChange={(e) => handleCategoryChange(e.target.value, level)}
                >
                    {level !== 0 && <MenuItem value=""><em>Pas de sous-catégorie</em></MenuItem>}
                    {currentCategories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    
    function renderEditCategorySelect(level, categories) {

        
        console.log("editSelectedCategoryPath" , editSelectedCategoryPath)
    
        // if (!categories || editSelectedCategoryPath.length === 0) {
        //     return null;
        // }
    
        let currentCategories = categories;
        for (let i = 0; i < level; i++) {
            const parentCategoryId = editSelectedCategoryPath[i];
            const parentCategory = currentCategories.find(cat => cat.id === parentCategoryId);
            if (!parentCategory || !parentCategory.subCategories) {
                return null;
            }
            currentCategories = parentCategory.subCategories;
        }

        const handleEditCategoryChange = (selectedCategoryId, level) => {
            let newPath = [...editSelectedCategoryPath];
        
            // Mettre à jour le chemin avec la nouvelle catégorie sélectionnée
            newPath[level] = selectedCategoryId;
        
            // Réduire le chemin pour les niveaux suivants, s'ils existent
            newPath = newPath.slice(0, level + 1);
        
            // Trouver les sous-catégories de la catégorie sélectionnée
            let currentCategory = categories_easydrinks;
            for (let i = 0; i <= level; i++) {
                const categoryId = newPath[i];
                const nextCategory = currentCategory.find(cat => cat.id === categoryId);
        
                // Si la catégorie actuelle a des sous-catégories, les préparer pour le niveau suivant
                if (nextCategory && nextCategory.subCategories && nextCategory.subCategories.length > 0) {
                    currentCategory = nextCategory.subCategories;
        
                    // Si c'est le dernier niveau sélectionné, ajouter un espace pour un nouveau sélecteur de sous-catégorie
                    if (i === level) {
                        newPath.push("");
                    }
                } else {
                    // Si aucune sous-catégorie n'est trouvée, arrêter la boucle
                    break;
                }
            }
        
            setEditSelectedCategoryPath(newPath);
        };
        
        
    
        const showNoSubcategoryOption = currentCategories.length > 0;

    return (
        <FormControl className={classes.formControl} key={level}>
            <InputLabel>{level === 0 ? 'Catégorie principale' : `Sous-catégorie niveau ${level}`}</InputLabel>
            <Select
                value={editSelectedCategoryPath[level] || ''}
                onChange={(e) => handleEditCategoryChange(e.target.value, level)}
                // displayEmpty
            >
                {showNoSubcategoryOption && (
                    <MenuItem style = {{paddingTop : 8 , paddingBottom : 8}} value="">
                        <em>{level === 0 ? 'Sélectionner une catégorie' : 'Pas de sous-catégorie'}</em>
                    </MenuItem>
                )}
                {currentCategories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
    }
    



      // ingredient


    const handleIngredientsChange = (newIngredients) => {
        setIngredients(newIngredients);
    };

    const handleScheduleChange = (newSchedule) => {
        setDrinkSchedule(newSchedule);
    };

    const handleAddCategory = () => {
        if (currentCategory && !categories.includes(currentCategory)) {
            setCategories([...categories, currentCategory]);
            setCurrentCategory(''); // Réinitialiser le champ après l'ajout
        }
    };

    useEffect(() => {
        if (selectedProductForEdit2) {
            const initialPricingMode = selectedProductForEdit.unit_price != null ? "perUnit" : "perLiter";
            setPricingMode2(initialPricingMode);
        }
    }, [selectedProductForEdit2])

    // const handleAddIngredient = () => {
    //     if (currentIngredient.name && !ingredients.find(ing => ing.name === currentIngredient.name)) {
    //         setIngredients([...ingredients, currentIngredient]);
    //         setCurrentIngredient({ name: '', description: '', brand: '', photo: null }); // Réinitialiser après l'ajout
    //     }
    // };



    const navigate = useNavigate();





    useEffect(() => {
        const fetchContainers = async () => {
            try {
                const response = await axiosInstance.get('/drinks/containers');
                setContainers(response.data);
                filterContainers(response.data, forGourd, forMug); // Filtrez les contenants dès qu'ils sont chargés
            } catch (error) {
                console.error('Erreur lors de la récupération des contenants', error);
            }
        };

        fetchContainers();
    }, []);

    const filterContainers = (containers, forGourd, forMug,forBox) => {
        let filtered = containers;

        if (forGourd) {
            filtered = containers.filter(container => container.gourde);
        } else if (forMug) {
            filtered = containers.filter(container => container.mug);
        }else if (forBox) {
            filtered = containers.filter(container => container.box);

        }
        

        setFilteredContainers(filtered);
    };




   
    




    // Mettez à jour les contenants filtrés chaque fois que l'utilisateur change de sélection
    useEffect(() => {
        filterContainers(containers, forGourd, forMug,forBox);
    }, [forGourd, forMug,forBox, containers]);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
               
                const response = await axiosInstance.get(`/drinks/drink-establishment-links/${id_etablissement}`);
                setProducts(response.data);
                
                console.log("test : --------------------------")
                console.log(response.data)
            } catch (error) {
                console.error('Erreur lors de la récupération des produits', error);
            }
        };

        fetchProducts();
    }, [id_etablissement]);


    useEffect(() => {
        const fetchDrinks = async () => {
            try {
                const response = await axiosInstance.get('/drinks/drinks');
                setDrinks(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des boissons', error);
            }
        };

        fetchDrinks();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (preconditionedWithDeposit && (isNaN(depositPrice) || depositPrice <= 0)) {
            alert("Le prix de la caution consigne doit être un nombre positif.");
            return;
        }
        

        const discountNumber = parseFloat(discount);
        if (isNaN(discountNumber) || discountNumber < 0 || discountNumber > 100) {
            alert("La réduction doit être un nombre entre 0 et 100.");
            return;
        }

        if (!drinkName || !imageFile) {
            alert("Veuillez fournir un nom et une photo pour la boisson.");
            return;
        }

         // Vérifie si un prix est fourni et valide
    let isPriceValid = false;
    if (pricingMode === 'perLiter') {
        const pricePerMl = parseFloat(price);
        isPriceValid = !isNaN(pricePerMl) && pricePerMl > 0;
    } else if (pricingMode === 'perUnit') {
        const unitPriceValue = parseFloat(unitPrice);
        isPriceValid = !isNaN(unitPriceValue) && unitPriceValue > 0;
    }

    // if (!isPriceValid) {
    //     alert("Veuillez fournir un prix valide pour la boisson.");
    //     return;
    // }

    // Vérifie si une catégorie est sélectionnée
    const numericValues =  selectedCategoryPath.filter(value => value !== "" && !isNaN(value));

    const selectedCategoryId = numericValues.length > 0 ? numericValues[numericValues.length - 1] : null;
    // const selectedCategoryId = selectedCategoryPath[selectedCategoryPath.length - 1];

    if (!selectedCategoryId) {
        alert("Veuillez sélectionner une catégorie pour la boisson.");
        return;
    }



        try {

            console.log('ingredients : ', ingredients)
         


            const formData = new FormData();
            // formData.append('drink_id', selectedDrinkId);
            formData.append('establishment_id', id_etablissement);
            formData.append('discount', discountNumber);
            if((contenantRequired) && (productType === 'food')  ){

                formData.append('for_gourd', forGourd);
                formData.append('for_mug', forMug);
                formData.append('for_box', forBox);

        
            }else{

                // formData.append('for_gourd', forGourd);
                // formData.append('for_mug', forMug);
                // formData.append('for_box', forBox);

                formData.append('for_gourd', false);
                formData.append('for_mug', false);
                formData.append('for_box', false);
            }

            if (productType === 'food' && contenantRequired) {
                formData.append('preconditioned_with_deposit', preconditionedWithDeposit);
                formData.append('deposit_price', depositPrice);
                formData.append('accepts_customer_container', acceptsCustomerContainer);
                formData.append('isVegetarian', isVegetarian);
            }
        
     

            formData.append('EnterpriseCodeRequired' , isEnterpriseCodeRequired)
            formData.append('refused_containers', JSON.stringify(refusedContainers));
            formData.append('pricing_mode', pricingMode);
            formData.append('schedule', JSON.stringify(drinkSchedule));
            formData.append('bio', bio);
            formData.append('visible', isVisible);
            formData.append('product_category', product_category)


            formData.append('drink_name', drinkName);
            formData.append('description', description);
            // formData.append('categories', JSON.stringify(categories));
            // formData.append('ingredients', JSON.stringify(ingredients));
            formData.append('container_disposition', hasAgreed)
            formData.append('container_id', selectedContainerId);
            formData.append('categorie_boisson', selectedCategoryId)
            formData.append('offered_drinks', offeredDrinks);
            formData.append('canBeOffered', canBeOffered);


            // console.log('ingredient : ' ,JSON.stringify(ingredients) )
            formData.append('ingredients', JSON.stringify(ingredients));
            ingredients.forEach((ingredient, index) => {
                if (ingredient.photo) {
                    formData.append(`ingredient_photo_${index}`, ingredient.photo);
                }
            });


            if (!hasAgreed) {
                formData.append('container_name', containerName);
                formData.append('container_brand', containerBrand);
                formData.append('container_volume', containerVolume);
                if (containerImage) {
                    formData.append('container_image', containerImage);
                }

            }




            if (pricingMode === 'perLiter') {
                formData.append('price_per_ml', price);
            } else if (pricingMode === 'perUnit') {
                formData.append('unit_price', unitPrice);
                formData.append('unit_volume_ml', unitVolume);
            }

            if (imageFile) {
                formData.append('image', imageFile);
            }
            console.log('---------------------------------------------')

            for (let pair of formData.entries()) {
                console.log(pair[0]+ ': ' + (pair[1].name ? pair[1].name : pair[1]));
            }

            formData.append('allowTransparentContainers', allowTransparentContainers);

        


            const response = await axiosInstance.post('/drinks/drink-establishment-links', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                alert('Produit ajouté avec succès à l\'établissement!');
            }

            window.location.reload()
        } catch (error) {
            console.error('Erreur lors de l\'ajout du produit', error);
        }
    };

    const deleteProduct = async (linkId) => {
        try {
            // const response = await axios.delete(`https://backoffice.gourdefriendly.green/drinks/drink-establishment-links/${linkId}`);
            const response = await axiosInstance.delete(`/drinks/drink-establishment-links/${linkId}`);

            if (response.status === 200) {
                alert('Produit supprimé avec succès !');
                // Mettre à jour la liste des produits après suppression
                setProducts(products.filter(product => product.id !== linkId));
            }
        } catch (error) {
            console.error('Erreur lors de la suppression du produit', error);
        }
    };


    const classes = useStyles();

    useEffect(() => {
        // fetchEstablishment(id_etablissement).then(setEstablishment);

        const fetchAndSetEstablishment = async () => {
            const establishmentData = await fetchEstablishment(id_etablissement);
            if (establishmentData) {
                SetNameEtablissement(establishmentData.name)
                sethasAccess(true);
            } else {
                sethasAccess(false);
            }
        };

        fetchAndSetEstablishment();


    }, [id_etablissement]);


    // =========================================================  container ==============================================================================

    const [isContainerModalOpen, setIsContainerModalOpen] = useState(false);

    // const handleAddContainer = (containerData) => {
    //     // Logique pour ajouter le contenant
    //     console.log(containerData);
    //     // Vous pouvez ici faire un appel API pour sauvegarder le contenant
    // };

    // const toggleContainerModal = () => {
    //     setIsContainerModalOpen(!isContainerModalOpen);
    // };

    useEffect(() => {
        const fetchContainers = async () => {
            console.log(`https://backend.csibon.app/container_chr/containers/establishment/${id_etablissement}`)
            try {

                const response = await fetch(`https://backend.csibon.app/container_chr/containers/establishment/${id_etablissement}`);
                if (!response.ok) {
                    throw new Error('Erreur réseau lors de la récupération des contenants.');
                }

                let containers_chr = await response.json();
                setContainers_chr(containers_chr);
            } catch (error) {
                console.error('Erreur lors de la récupération des contenants:', error);
            }
        };

        fetchContainers();
    }, [id_etablissement]); // Exécuter lorsque establishmentId change


    // ===================================================== modif fiche boisson ========================================================================

    // Fonction pour ouvrir le dialogue de modification avec les données du produit
    const openEditModal = (product) => {
        setSelectedProductForEdit(product);
        setSelectedProductForEdit2(product);
        setSelectedProductIngredients(product.ingredients || []);
        setAllowTransparentContainers(product.allow_transparent_containers || false);
        setEditOfferedDrinks(product.drinks_offered || 0)


        // Initialiser les catégories sélectionnées pour la modification
    const categoryPath = product.categories_boisson.map(cat => cat.id);
    console.log('___________________________________________________________')
    console.log('product.categories_boisson : ' , categoryPath)
    setEditSelectedCategoryPath(categoryPath);
    setDrinksCanBeOffered(product.drinks_offered || false); // Initialiser avec la valeur existante ou false


        setIsEditModalOpen(true);
    };
    // Fonction pour gérer la fermeture du dialogue de modification
    const closeEditModal = () => {
        setSelectedProductForEdit(null);
        setSelectedProductForEdit2(null);
        setIsEditModalOpen(false);

    };


    const handleEditSubmit = async (updatedProductData) => {
        const formData = new FormData();

        if (preconditionedWithDeposit && (isNaN(depositPrice) || depositPrice <= 0)) {
            alert("Le prix de la caution consigne doit être un nombre positif.");
            return;
        }
        



        console.log("updatedProductData : ", updatedProductData)

        const numericValues = editSelectedCategoryPath.filter(value => value !== "" && !isNaN(value));

        const editselectedCategoryId = numericValues.length > 0 ? numericValues[numericValues.length - 1] : null;

        console.log('editSelectedCategoryPath' , editSelectedCategoryPath)




        // Exemple :
        // formData.append('drink_id', updatedProductData.drink_id);
        // formData.append('discount', updatedProductData.discount);
        formData.append('allowTransparentContainers', allowTransparentContainers);

        formData.append('bio', updatedProductData.bio);
        if (updatedProductData.container_disposition) {


            formData.append('id_container_gourdefriendly', updatedProductData.id_container_gourdefriendly);
        }
        formData.append('for_gourde', updatedProductData.for_gourde);
        formData.append('for_mug', updatedProductData.for_mug);
        formData.append('for_box', updatedProductData.for_box);
        formData.append('offered_drinks', editOfferedDrinks);

        if (updatedProductData.product_category === 'food') {
            formData.append('preconditioned_with_deposit', updatedProductData.preconditioned_with_deposit);
            formData.append('deposit_price', updatedProductData.deposit_price);
            formData.append('accepts_customer_container', updatedProductData.accepts_customer_container);
            formData.append('isVegetarian', updatedProductData.is_vegetarian);
        }


        formData.append('refused_containers', JSON.stringify(updatedProductData.refused_containers_id));
        // formData.append('price_per_ml', updatedProductData.price_per_ml);
        // formData.append('unit_price', updatedProductData.unit_price);
        // formData.append('unit_volume_ml', updatedProductData.unit_volume_ml);
        formData.append('hourly', JSON.stringify(updatedProductData.hourly));


        formData.append('container_disposition', updatedProductData.container_disposition);
        formData.append('discount', updatedProductData.discount);


        if (pricingMode2 === 'perLiter') {
            formData.append('price_per_ml', updatedProductData.price_per_ml);

            formData.append('unit_price', null);
            formData.append('unit_volume_ml', null);
        } else if (pricingMode2 === 'perUnit') {
            formData.append('unit_price', updatedProductData.unit_price);
            formData.append('unit_volume_ml', updatedProductData.unit_volume_ml);

            formData.append('price_per_ml', null);
        }

        formData.append('establishment_category_id', editselectedCategoryId)
        formData.append('name', updatedProductData.name);
        formData.append('description', updatedProductData.description);
        formData.append('EnterpriseCodeRequired', updatedProductData.EnterpriseCodeRequired);
        formData.append('canBeOffered', drinkcCanBeOffered);


        // Ajouter les ingrédients à formData
        formData.append('ingredients', JSON.stringify(selectedProductIngredients.map(ingredient => {
            return { id: ingredient.id, name: ingredient.name, brand: ingredient.brand, description: ingredient.description };
        })));

        // Pour chaque ingrédient, vérifiez si une photo est présente et ajoutez-la à formData
        selectedProductIngredients.forEach((ingredient, index) => {
            if (ingredient.photo && typeof ingredient.photo === 'object') {
                // Cela signifie que c'est un nouveau fichier, pas un URL
                console.log(ingredient.photo)
                formData.append(`ingredient_photo_${index}`, ingredient.photo);
            }
        });

        console.log("edit : ", JSON.stringify(selectedProductIngredients.map(ingredient => {
            return { id: ingredient.id, name: ingredient.name, brand: ingredient.brand, description: ingredient.description };
        })))








        // Ajoutez l'image si elle est présente
        if (updatedProductData.imageFile) {
            console.log(updatedProductData.imageFile)
            formData.append('image', updatedProductData.imageFile);
        }

        for (let [key, value] of formData.entries()) {
            console.log(key, " : ", value);
        }

        console.log(`https://backend.csibon.app/drinks/drink-establishment-links/${updatedProductData.id}`)

        try {
            const response = await fetch(`https://backend.csibon.app/drinks/drink-establishment-links/${updatedProductData.id}`, {
                method: 'PUT',
                headers: {
                    // Ajoutez les en-têtes nécessaires, sauf 'Content-Type' pour permettre à fetch de définir le type multipart/form-data correctement
                    'Authorization': `Bearer `,
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Traiter la réponse
            const data = await response.json();
            console.log(data);
            closeEditModal();
            window.location.reload()
        } catch (error) {
            console.error('Erreur lors de la mise à jour du produit', error);
        }
    };



    // Ajoutez des états pour suivre la sélection de gourde ou mug
    const [isForGourd, setIsForGourd] = useState(selectedProductForEdit?.forGourd);
    const [isForMug, setIsForMug] = useState(selectedProductForEdit?.forMug);

    // Fonction pour filtrer les contenants en fonction de la sélection de gourde ou mug
    const filterContainersForSelection = () => {
        if (isForGourd) {
            return containers.filter(container => container.gourde);
        } else if (isForMug) {
            return containers.filter(container => container.mug);
        }
        return containers; // Retourne tous les contenants si aucune sélection spécifique n'est faite
    };

    const scheduleString = selectedProductForEdit?.hourly;
    let scheduleObject = [];

    try {
        scheduleObject = scheduleString ? JSON.parse(scheduleString) : [];
    } catch (error) {
        console.error("Erreur lors de la conversion des données horaires :", error);
    }

    // ============================================================== rendre visible ===============================================================

    const toggleVisibility = async (product) => {
        try {
            // Mise à jour de la visibilité sur le serveur
            const response = await axiosInstance.put(`drinks/update-visibility/${product.id}`, {
                visible: !product.visible
            });

            if (response.status === 200) {
                // Mise à jour de l'état local pour refléter le changement
                setProducts(products.map(p => {
                    if (p.id === product.id) {
                        return { ...p, visible: !p.visible };
                    }
                    return p;
                }));
                alert(`La visibilité de ${product.drink.nom} a été modifiée.`);
            }
        } catch (error) {
            console.error('Erreur lors de la modification de la visibilité', error);
        }
    };


 


    if (!hasAccess) {
        return <AccessDeniedMessage />;
    }

   

    function renderCategories(categories) {
        return (
            <div style={{ marginTop: '10px' }}>
                {categories.map(category => (
                    <Chip 
                        key={category.id}
                        label={category.name}
                        style={{ marginRight: '5px', marginBottom: '5px' }}
                    />
                ))}
            </div>
        );
    }
    

    return (
        <>
            <AdminNavbar establishmentId={id_etablissement} name={establishment} />
            <IconButton onClick={() => navigate(`/accueil`)} color="primary" aria-label="retour">
                <ArrowBackIcon />
            </IconButton>

          


            <div className={classes.root}>


                <Typography variant="h4" gutterBottom className={classes.header}>
                    Ajouter un produit à l'établissement {name_etablissement}
                </Typography>

                <form onSubmit={handleSubmit}>

                <div className={classes.section}>
    <Grid container alignItems="center" spacing={1}>
     
        <Grid item>
            <Typography 
                style={{
                    fontWeight: productType === 'drink' ? 'bold' : 'normal',
                    color: productType === 'drink' ? '#1976d2' : 'inherit' // Bleu pour la surbrillance
                }}
            >
                Boisson
            </Typography>
        </Grid>


        <Grid item>
            <FormControlLabel
                control={
                    <Switch
                        checked={productType === 'food'} // Inverse la logique ici pour correspondre à votre besoin
                        onChange={(e) => setProductType(e.target.checked ? 'food' : 'drink')} // Change également ici pour inverser la sélection
                        name="productTypeSwitch"
                    />
                }
                label=""
            />
        </Grid>
        <Grid item>
            <Typography 
                style={{
                    fontWeight: productType === 'food' ? 'bold' : 'normal',
                    color: productType === 'food' ? '#1976d2' : 'inherit' // Bleu pour la surbrillance
                }}
            >
                Nourriture
            </Typography>
        </Grid>
    </Grid>
</div>


<div className={classes.section}>
    <FormControlLabel
        control={
            <Checkbox
                checked={isEnterpriseCodeRequired}
                onChange={(e) => setIsEnterpriseCodeRequired(e.target.checked)}
                name="enterpriseCodeRequired"
                color="primary"
            />
        }
        label="Disponible uniquement pour les utilisateurs avec code entreprise"
    />
</div>

{productType === 'food' && (
<div className={classes.section}>
    <FormControlLabel
        control={
            <Checkbox
                checked={contenantRequired}
                onChange={(e) => setContenantRequired(e.target.checked)}
                name="contenantRequired"
                color="primary"
            />
        }
        label="Contenant requis"
    />
</div>

    )}


{productType === 'food' && contenantRequired && (
    <div className={classes.section}>
        <Typography variant="h6">Options de Contenant</Typography>
        
        <FormControlLabel
            control={
                <Checkbox
                    checked={preconditionedWithDeposit}
                    onChange={(e) => setPreconditionedWithDeposit(e.target.checked)}
                    name="preconditionedWithDeposit"
                    color="primary"
                />
            }
            label="Préconditionné avec contenant consigné"
        />
        {preconditionedWithDeposit && (
            <TextField
                label="Prix de la caution consigne (€)"
                type="number"
                value={depositPrice}
                onChange={(e) => setDepositPrice(e.target.value)}
                fullWidth
                margin="normal"
            />
        )}

        <FormControlLabel
            control={
                <Checkbox
                    checked={acceptsCustomerContainer}
                    onChange={(e) => setAcceptsCustomerContainer(e.target.checked)}
                    name="acceptsCustomerContainer"
                    color="primary"
                />
            }
            label="Accepte contenant du client"
        />
    </div>
)}




               

                    <div className={classes.section}>

                        <TextField
                            label="Nom"
                            value={drinkName}
                            onChange={(e) => setDrinkName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />

                    </div>

                    <div className={classes.section}>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                        />

                    </div>

                    {productType === 'food' && (
                        <div className={classes.section}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isVegetarian}
                                    onChange={(e) => setIsVegetarian(e.target.checked)}
                                    name="isVegetarian"
                                    color="primary"
                                />
                            }
                            label="Végétarien"
                        />
                        </div>
                    )}



<div className={classes.section}>
            {[...Array(selectedCategoryPath.length + 1)].map((_, index) => renderCategorySelect(index))}
        </div>
                    <div className={classes.section}>

                        <ManageIngredients initialIngredients={ingredients} setIngredientsinitial={setIngredients} onIngredientsChange={handleIngredientsChange} imagePreviewUrl={imagePreviewUrl} setImagePreviewUrl={setImagePreviewUrl} ingredientForm={ingredientForm} setIngredientForm={setIngredientForm} />

                    </div>



                  

                    <div className={classes.section}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hasAgreed}
                                    onChange={(e) => setHasAgreed(e.target.checked)}
                                    name="hasAgreed"
                                    color="primary"
                                />
                            }
                            label="Je m'engage à fournir un contenant"
                        />

                        {hasAgreed && (
                            <>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>Contenant</InputLabel>
                                    <Select
                                        value={selectedContainerId} // Vous devrez définir cet état
                                        onChange={(e) => setSelectedContainerId(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>Choisissez un contenant...</em>
                                        </MenuItem>
                                        {containers_chr.map((container) => (
                                            <MenuItem key={container.id} value={container.id}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={`https://backend.csibon.app/static/container_images/${container.image_url}`}
                                                        alt={container.name}
                                                        style={{ width: '40px', height: '40px', marginRight: '10px' }}
                                                    />
                                                    <span>{container.name} ({container.brand})</span>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>


                        )}

                    </div>


                        
                    {/* <div className={classes.section}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={forGourd}
                                    onChange={(e) => setForGourd(e.target.checked)}
                                    name="forGourd"
                                    color="primary"
                                />
                            }
                            label="Pour Gourde"
                        />



                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={forMug}
                                    onChange={(e) => setForMug(e.target.checked)}
                                    name="forMug"
                                    color="primary"
                                />
                            }
                            label="Pour Mug"
                        />

                    </div> */}

{productType === 'drink' && (
    <div className={classes.section}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={forGourd}
                    onChange={(e) => setForGourd(e.target.checked)}
                    name="forGourd"
                    color="primary"
                />
            }
            label="Pour Gourde"
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={forMug}
                    onChange={(e) => setForMug(e.target.checked)}
                    name="forMug"
                    color="primary"
                />
            }
            label="Pour Mug"
        />
    </div>
)}

{productType === 'drink' && (

                    <div className={classes.section}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={bio}
                                    onChange={(e) => setBio(e.target.checked)}
                                    name="forBio"
                                    color="primary"
                                />
                            }
                            label="boisson bio"
                        />







                    </div>

                        )}

                    <div className={classes.section}>
                        <DrinkSchedule onScheduleChange={handleScheduleChange} />
                    </div>

                    <div className={classes.section}>
    <FormControlLabel
        control={
            <Checkbox
                checked={allowTransparentContainers}
                onChange={(e) => setAllowTransparentContainers(e.target.checked)}
                name="allowTransparentContainers"
                color="primary"
            />
        }
        label="Autoriser les contenants opaques"
    />
</div>







                    <div className={classes.section}>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Contenants refusés</InputLabel>
                            <Select
                                multiple
                                value={refusedContainers}
                                onChange={(e) => setRefusedContainers(e.target.value)}
                                renderValue={(selected) => selected.join(', ')}
                            >

                                {filteredContainers.map(container => (
                                    <MenuItem key={container.id} value={container.id}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={`https://backend.csibon.app/static/container_images/${container.image_url}`}
                                                alt={container.name}
                                                style={{ width: '40px', height: '40px', marginRight: '10px' }}
                                            />
                                            <span>{container.name} ({container.brand})</span>
                                        </div>
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                    </div>



                    {productType === 'drink' && (

                    <div className={classes.section}>



                        <FormControl component="fieldset">
                            <FormLabel component="legend">Mode de tarification</FormLabel>
                            <RadioGroup
                                row
                                aria-label="pricingMode"
                                name="pricingMode"
                                value={pricingMode}
                                onChange={(e) => setPricingMode(e.target.value)}
                            >
                                <FormControlLabel value="perLiter" control={<Radio />} label="Prix au litre" />
                                <FormControlLabel value="perUnit" control={<Radio />} label="Prix à l'unité" />
                            </RadioGroup>
                        </FormControl>

                        {pricingMode === 'perLiter' && (
                            <TextField
                                label="Prix au litre"
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        )}

                        {pricingMode === 'perUnit' && (
                            <>
                                <TextField
                                    label="Prix à l'unité"
                                    type="number"
                                    value={unitPrice}
                                    onChange={(e) => setUnitPrice(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Volume par unité (cl)"
                                    type="number"
                                    value={unitVolume}
                                    onChange={(e) => setUnitVolume(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            </>
                        )}

                    </div>

                    )}


{productType === 'food' && (

<div className={classes.section}>



    <FormControl component="fieldset">
        <FormLabel component="legend">Mode de tarification</FormLabel>
        {/* <RadioGroup
            row
            aria-label="pricingMode"
            name="pricingMode"
            value={pricingMode}
            onChange={(e) => setPricingMode(e.target.value)}
        >
            <FormControlLabel value="perLiter" control={<Radio />} label="Prix au litre" />
            <FormControlLabel value="perUnit" control={<Radio />} label="Prix à l'unité" />
        </RadioGroup> */}
    </FormControl>

    {/* {pricingMode === 'perLiter' && (
        <TextField
            label="Prix au litre"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
            margin="normal"
        />
    )} */}

    {pricingMode === 'perUnit' && (
        <>
            <TextField
                label="Prix"
                type="number"
                value={unitPrice}
                onChange={(e) => setUnitPrice(e.target.value)}
                fullWidth
                margin="normal"
            />
            {/* <TextField
                label="Volume par unité (cl)"
                type="number"
                value={unitVolume}
                onChange={(e) => setUnitVolume(e.target.value)}
                fullWidth
                margin="normal"
            /> */}
        </>
    )}

</div>

)}




                    <div className={classes.section}>


                        <TextField
                            label="Réduction (%)"
                            type="number"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={discount < 0 || discount > 100}
                            helperText={(discount < 0 || discount > 100) && "La réduction doit être un pourcentage (0-100)"}
                        />

                    </div>


                    <div className={classes.section}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImageFile(e.target.files[0])}
                        />
                    </div>

                    {/* <div className={classes.section}>
                    <TextField
    label="Nombre de boissons offertes"
    type="number"
    value={offeredDrinks}
    onChange={(e) => {
        const value = e.target.value;
        if (value >= 0) {
            setOfferedDrinks(value);
        }
    }}
    fullWidth
    margin="normal"
/>

</div> */}

<div className={classes.section}>
    <FormControlLabel
        control={
            <Switch
                checked={canBeOffered}
                onChange={(e) => setCanBeOffered(e.target.checked)}
                name="canBeOfferedSwitch"
                color="primary"
            />
        }
        label="Boisson peut être offerte"
    />
</div>


                    <div className={classes.section}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isVisible}
                                    onChange={(e) => setIsVisible(e.target.checked)}
                                />
                            }
                            label="Visible"
                        />
                    </div>
                    <Button variant="contained" color="primary" type="submit" startIcon={<AddIcon />}>
                        Ajouter le produit
                    </Button>
                </form>

                <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
                    Produits pour l'établissement {id_etablissement}
                </Typography>
                <Grid container spacing={2} direction="column">
      {products.map(product => (
        <Grid item key={product.id}>
          <Card className={classes.productCard}>
            <Grid container>

              {/* Image du produit */}
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  alt={product.name}
                  height="140"
                  image={product.image_url ? `${product.image_url}` : `${product.drink.url_image}`}
                  title={product.name}
                />
              </Grid>

              {/* Informations du Produit */}
              <Grid item xs={12} md={8}>
                <CardContent>
                  <Typography variant="h6">{product?.name}</Typography>
                  <Typography variant="body2">{product.description}</Typography>
                  {product.for_gourde && <Typography variant="body2">Pour Gourde</Typography>}
                  {product.for_mug && <Typography variant="body2">Pour Mug</Typography>}
                  {product.for_box && <Typography variant="body2">Pour Box</Typography>}
                  {product.price_per_ml && <Typography variant="body2">Prix au litre: {product.price_per_ml}</Typography>}
                  {product.unit_price && <Typography variant="body2">Prix à l'unité: {product.unit_price}</Typography>}
                  {product.unit_volume_ml || product.unit_volume_ml != 0 && <Typography variant="body2">Volume unitaire: {product.unit_volume_ml} ml</Typography>}
                  <Typography variant="body2" color={product.discount > 50 ? 'error' : 'inherit'}>
                    Réduction: {product.discount ? `${product.discount}%` : 'Aucune'}
                  </Typography>
                  {product?.is_vegetarian && (
  <Typography 
    variant="body2" 
    style={{ 
      color: 'green', 
      display: 'flex', 
      alignItems: 'center' 
    }}
  >
    <span style={{ 
      backgroundColor: 'green', 
      width: '10px', 
      height: '10px', 
      borderRadius: '50%', 
      display: 'inline-block', 
      marginRight: '5px' 
    }}></span>
    Végétarien
  </Typography>
)}

                  {/* Ingrédients */}
                  <Typography variant="subtitle1">Ingrédients:</Typography>
                  <List>
                    {product?.ingredients && Array.isArray(product.ingredients) && product.ingredients.map(ingredient => (
                      <ListItem key={ingredient?.id}>
                        <ListItemAvatar>
                          <Avatar src={ingredient?.photo || "URL_d'une_image_par_défaut"} alt={`Ingrédient: ${ingredient?.name}`} />
                        </ListItemAvatar>
                        <ListItemText primary={ingredient?.name} secondary={ingredient?.description} />
                      </ListItem>
                    ))}
                  </List>

                  {/* Contenant mis à disposition */}
                  {product.allow_transparent_containers &&  (
                    
                  <Typography variant="body2">
    Contenants opaques autorisés: {product.allow_transparent_containers ? 'Oui' : 'Non'}
</Typography>
                  )}


{/* {product.drinks_offered && (
  <Typography variant="body2">
    Nombre de boissons offertes par utilisateur : {product.drinks_offered}
  </Typography>
)} */}
{product.drinks_offered && (
  <Typography variant="body2">
    Cette boisson peut être offerte.
  </Typography>
)}



                  {product.container_disposition && product.container_info && (
                    <div>
                      <Typography variant="subtitle1">Contenant:</Typography>
                      <Typography variant="body2">
                        Nom: {product.container_info?.name}
                        <br />
                        Marque: {product.container_info?.brand}
                        <br />
                        Volume: {product.container_info?.volume}
                      </Typography>
                      {product?.container_info.image_url && (
                        <img src={`https://backend.csibon.app/static/container_images/${product.container_info.image_url}`} alt={product?.container_info?.name} style={{ width: '100px', height: 'auto', marginTop: '10px' }} />
                      )}
                    </div>
                  )}

                  {/* Catégories */}
                  {/* <Typography variant="subtitle1">Catégories:</Typography>
                  {renderCategories(product?.categories_boisson)} */}

                   {/* Catégories */}
                   <Typography variant="subtitle1">Catégories:</Typography>
                    {renderCategories(product.categories_boisson)}

                  {/* Actions */}
                  <CardActions>
                    <Button onClick={() => openEditModal(product)} color="primary">Modifier</Button>
                    <Button onClick={() => deleteProduct(product.id)} color="secondary">Supprimer</Button>
                    <Button
                      variant="contained"
                      color={product.visible ? "secondary" : "primary"}
                      onClick={() => toggleVisibility(product)}
                    >
                      {product.visible ? "Cacher" : "Afficher"}
                    </Button>
                  </CardActions>
                </CardContent>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
            </div>

            <Dialog open={isEditModalOpen} onClose={closeEditModal} fullWidth maxWidth="md">
                <DialogTitle>Modifier le produit</DialogTitle>
                {console.log('selectedProductForEdit :', selectedProductForEdit)}
                <DialogContent>

    

<div className={classes.section}>
    <FormControlLabel
        control={
            <Checkbox
                checked={selectedProductForEdit?.EnterpriseCodeRequired || false}
                onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, EnterpriseCodeRequired: e.target.checked })}
                name="EnterpriseCodeRequired"
                color="primary"
            />
        }
        label="Disponible uniquement pour les utilisateurs avec code entreprise"
    />
</div>

{selectedProductForEdit?.product_category === 'food' && (
  <div className={classes.section}>
    <FormControlLabel
      control={
        <Checkbox
          checked={selectedProductForEdit?.is_vegetarian || false}
          onChange={(e) => setSelectedProductForEdit({ 
            ...selectedProductForEdit, 
            is_vegetarian: e.target.checked 
          })}
          name="isVegetarianEdit"
          color="primary"
        />
      }
      label="Végétarien"
    />
  </div>
)}

{selectedProductForEdit?.product_category === 'food' && (
                <div className={classes.section}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedProductForEdit.preconditioned_with_deposit || false}
                                onChange={(e) => setSelectedProductForEdit({ 
                                    ...selectedProductForEdit, 
                                    preconditioned_with_deposit: e.target.checked 
                                })}
                                name="preconditionedWithDepositEdit"
                                color="primary"
                            />
                        }
                        label="Préconditionné avec contenant consigné"
                    />
           
                    {selectedProductForEdit.preconditioned_with_deposit && (
                        <TextField
                            label="Prix de la caution consigne (€)"
                            type="number"
                            value={selectedProductForEdit.deposit_price || ''}
                            onChange={(e) => setSelectedProductForEdit({ 
                                ...selectedProductForEdit, 
                                deposit_price: e.target.value 
                            })}
                            fullWidth
                            margin="normal"
                        />
                    )}

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedProductForEdit.accepts_customer_container || false}
                                onChange={(e) => setSelectedProductForEdit({ 
                                    ...selectedProductForEdit, 
                                    accepts_customer_container: e.target.checked 
                                })}
                                name="acceptsCustomerContainerEdit"
                                color="primary"
                            />
                        }
                        label="Accepte contenant du client"
                    />
                </div>
            )}


{/* {selectedProductForEdit?.product_category === 'food' && (
    <div className={classes.section}>
        <FormControlLabel
            control={
                <Checkbox
                    checked={selectedProductForEdit?.for_box || false}
                    onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, for_box: e.target.checked })}

                    name="contenantRequired"
                    color="primary"
                />
            }
            label="Contenant requis"
        />
    </div>
)} */}



<h2>Nom : </h2>
                    <TextField
                        label="Nom de la boisson"
                        value={selectedProductForEdit?.name}
                        onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, name: e.target.value })}
                        fullWidth
                        margin="normal"
                    />


<h2>Description : </h2>

                    <TextField
                        label="Description"
                        value={selectedProductForEdit?.description}
                        onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, description: e.target.value })}
                        fullWidth
                        margin="normal"
                        multiline

                    />



                    {/* <FormControl className={classes.formControl}>
                        <InputLabel>Catégorie</InputLabel>
                        <Select
                            value={selectedProductForEdit?.establishment_category_id}
                            onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, establishment_category_id: e.target.value })}
                        >
                            {categoryOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}

                    {/* <h2>Catégories : </h2> */}
{/* 
{[...Array(editSelectedCategoryPath.length  )].map((_, index) =>
    renderEditCategorySelect(index, categories_easydrinks)
)} */}

<h2>Catégories : </h2>
{
  // Utilisez Math.max pour s'assurer qu'au moins un sélecteur est généré
  [...Array(Math.max(editSelectedCategoryPath.length, 1))].map((_, index) =>
    renderEditCategorySelect(index, categories_easydrinks)
  )
}


<h2>Ingrédients : </h2>
                    <ManageIngredients
                        initialIngredients={selectedProductIngredients}
                        setIngredientsinitial={setSelectedProductIngredients}
                        onIngredientsChange={setSelectedProductIngredients}
                        imagePreviewUrl={imagePreviewUrl}
                        setImagePreviewUrl={setImagePreviewUrl}
                        ingredientForm={ingredientForm}
                        setIngredientForm={setIngredientForm}
                    />
{selectedProductForEdit?.product_category === 'drink' && (
    <>
<h2>Bio : </h2>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedProductForEdit?.bio || false}
                                onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, bio: e.target.checked })}
                                name="bio"
                                color="primary"
                            />
                        }
                        label="Bio"
                    />
</>
)}


<h2> Remise d'un contenant : </h2>
                    {/* Autres champs pour les options de gourde, mug, horaires, etc. */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedProductForEdit?.container_disposition || false}
                                onChange={(e) => setSelectedProductForEdit({ ...selectedProductForEdit, container_disposition: e.target.checked })}
                                name="container_disposition"
                                color="primary"
                            />
                        }
                        label="Remise d'un contenant"
                    />

                    {/* Select pour choisir le contenant */}
                    {selectedProductForEdit?.container_disposition && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Contenant</InputLabel>
                            <Select
                                value={selectedProductForEdit?.id_container_gourdefriendly || ''}
                                onChange={(e) => {
                                    console.log('contenant : ', e.target.value)
                                    setSelectedProductForEdit({
                                        ...selectedProductForEdit,

                                        id_container_gourdefriendly: e.target.value


                                    });
                                }}
                                disabled={!selectedProductForEdit?.container_disposition}
                            >
                                {console.log(selectedProductForEdit)}
                                <MenuItem value="">
                                    <em>Choisissez un contenant...</em>
                                </MenuItem>
                                {containers_chr.map((container) => (
                                    <MenuItem key={container.id} value={container.id}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={`https://backend.csibon.app/static/container_images/${container.image_url}`}
                                                alt={container.name}
                                                style={{ width: '40px', height: '40px', marginRight: '10px' }}
                                            />
                                            <span>{container.name} ({container.brand})</span>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                    )}
{selectedProductForEdit?.product_category === 'drink' && (
<>
<h2>Type de contenant : </h2>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedProductForEdit?.for_gourde || false}
                                onChange={(e) => {
                                    setSelectedProductForEdit({ ...selectedProductForEdit, for_gourde: e.target.checked })

                                    // setIsForGourd(e.target.checked)
                                }
                                }
                            />
                        }
                        label="Pour Gourde"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedProductForEdit?.for_mug || false}
                                onChange={(e) => {
                                    setSelectedProductForEdit({ ...selectedProductForEdit, for_mug: e.target.checked })
                                    // setIsForMug(e.target.checked)

                                }

                                }
                            />
                        }
                        label="Pour Mug"
                    />

</>
)}



                    {/* <DrinkSchedule
                        schedule={selectedProductForEdit?.hourly || []}
                        onScheduleChange={(newSchedule) =>
                            setSelectedProductForEdit({ ...selectedProductForEdit, hourly: newSchedule })
                        }
                    /> */}

<h2>Horaires de la boisson : </h2>

                    <DrinkSchedule
                        schedule={scheduleObject}
                        onScheduleChange={(newSchedule) =>
                            setSelectedProductForEdit({ ...selectedProductForEdit, hourly: JSON.stringify(newSchedule) })
                        }
                    />


<FormControlLabel
    control={
        <Checkbox
            checked={allowTransparentContainers}
            onChange={(e) => setAllowTransparentContainers(e.target.checked)}
            name="allowTransparentContainers"
            color="primary"
        />

    }
    label="Autoriser les contenants opaques"
/>
{/* 
<TextField
    label="Nombre de boissons offertes"
    type="number"
    value={editOfferedDrinks}
    onChange={(e) => {
        const value = e.target.value;
        if (value >= 0) {
            setEditOfferedDrinks(value);
        }
    }}
    fullWidth
    margin="normal"
/> */}

<div className={classes.section}>
    <FormControlLabel
        control={
            <Switch
                checked={drinkcCanBeOffered}
                onChange={(e) => setDrinksCanBeOffered(e.target.checked)}
                name="canBeOfferedSwitchEdit"
                color="primary"
            />
        }
        label="Boisson peut être offerte"
    />
</div>



<h2>Les contenants refusés : </h2>

                    <FormControl className={classes.formControl}>
                        <InputLabel>Contenants refusés</InputLabel>
                        <Select
                            multiple
                            value={selectedProductForEdit?.refused_containers_id || []}
                            onChange={(e) =>
                                setSelectedProductForEdit({
                                    ...selectedProductForEdit,
                                    refused_containers_id: e.target.value
                                })
                            }
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {filterContainersForSelection().map(container => (
                                <MenuItem key={container.id} value={container.id}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={`https://backend.csibon.app/static/container_images/${container.image_url}`}
                                            alt={container.name}
                                            style={{ width: '40px', height: '40px', marginRight: '10px' }}
                                        />
                                        <span>{container.name} ({container.brand})</span>
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                    {selectedProductForEdit?.product_category === 'drink' && (
                        <>
                    <h2>Tarif de la boisson : </h2>
                    {/* Champs pour le mode de tarification */}
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Mode de tarification</FormLabel>
                        <RadioGroup
                            row
                            aria-label="pricingMode"
                            name="pricingMode"
                            value={pricingMode2}
                            onChange={(e) =>
                                // setSelectedProductForEdit({
                                //     ...selectedProductForEdit,
                                //     pricingMode: e.target.value
                                // })
                                setPricingMode2(e.target.value)
                            }
                        >
                            <FormControlLabel value="perLiter" control={<Radio />} label="Prix au litre" />
                            <FormControlLabel value="perUnit" control={<Radio />} label="Prix à l'unité" />
                        </RadioGroup>
                    </FormControl>





                    {pricingMode2 === 'perLiter' && (
                        <TextField
                            label="Prix au litre"
                            type="number"
                            value={selectedProductForEdit?.price_per_ml}
                            onChange={(e) =>
                                setSelectedProductForEdit({
                                    ...selectedProductForEdit,
                                    price_per_ml: e.target.value
                                })
                            }
                            fullWidth
                            margin="normal"
                        />
                    )}

                    {pricingMode2 === 'perUnit' && (
                        <>
                            <TextField
                                label="Prix à l'unité"
                                type="number"
                                value={selectedProductForEdit?.unit_price}
                                onChange={(e) =>
                                    setSelectedProductForEdit({
                                        ...selectedProductForEdit,
                                        unit_price: e.target.value
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Volume par unité (cl)"
                                type="number"
                                value={selectedProductForEdit?.unit_volume_ml}
                                onChange={(e) =>
                                    setSelectedProductForEdit({
                                        ...selectedProductForEdit,
                                        unit_volume_ml: e.target.value
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />


                        </>
                    )}

                    </>

                )}


{selectedProductForEdit?.product_category === 'food' && (
                        <>
                    <h2>Tarif  : </h2>
                    {/* Champs pour le mode de tarification */}
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Mode de tarification</FormLabel>
                        {/* <RadioGroup
                            row
                            aria-label="pricingMode"
                            name="pricingMode"
                            value={pricingMode2}
                            onChange={(e) =>
                                // setSelectedProductForEdit({
                                //     ...selectedProductForEdit,
                                //     pricingMode: e.target.value
                                // })
                                setPricingMode2(e.target.value)
                            }
                        >
                            <FormControlLabel value="perLiter" control={<Radio />} label="Prix au litre" />
                            <FormControlLabel value="perUnit" control={<Radio />} label="Prix à l'unité" />
                        </RadioGroup> */}
                    </FormControl>





                    {/* {pricingMode2 === 'perLiter' && (
                        <TextField
                            label="Prix au litre"
                            type="number"
                            value={selectedProductForEdit?.price_per_ml}
                            onChange={(e) =>
                                setSelectedProductForEdit({
                                    ...selectedProductForEdit,
                                    price_per_ml: e.target.value
                                })
                            }
                            fullWidth
                            margin="normal"
                        />
                    )} */}

                    {pricingMode2 === 'perUnit' && (
                        <>
                            <TextField
                                label="Prix à l'unité"
                                type="number"
                                value={selectedProductForEdit?.unit_price}
                                onChange={(e) =>
                                    setSelectedProductForEdit({
                                        ...selectedProductForEdit,
                                        unit_price: e.target.value
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />
                            {/* <TextField
                                label="Volume par unité (cl)"
                                type="number"
                                value={selectedProductForEdit?.unit_volume_ml}
                                onChange={(e) =>
                                    setSelectedProductForEdit({
                                        ...selectedProductForEdit,
                                        unit_volume_ml: e.target.value
                                    })
                                }
                                fullWidth
                                margin="normal"
                            /> */}


                        </>
                    )}

                    </>

                )}


<h2>Réduction : </h2>

                    <TextField
                        label="Réduction (%)"
                        type="number"
                        value={selectedProductForEdit?.discount}
                        onChange={(e) =>
                            setSelectedProductForEdit({
                                ...selectedProductForEdit,
                                discount: e.target.value
                            })
                        }
                        fullWidth
                        margin="normal"
                        error={selectedProductForEdit?.discount < 0 || selectedProductForEdit?.discount > 100}
                        helperText={(selectedProductForEdit?.discount < 0 || selectedProductForEdit?.discount > 100) && "La réduction doit être un pourcentage (0-100)"}
                    />


                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                            setSelectedProductForEdit({
                                ...selectedProductForEdit,
                                imageFile: e.target.files[0]
                            })
                        }
                        style={{ marginTop: '10px' }}
                    />
                    {selectedProductForEdit?.imageFile && (
                        <img
                            src={URL.createObjectURL(selectedProductForEdit?.imageFile)}
                            alt="Aperçu"
                            style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                        />
                    )}

                    {/* Autres champs pour les options de gourde, mug, horaires, etc. */}

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditModal} color="secondary">Annuler</Button>
                    <Button onClick={() => handleEditSubmit(selectedProductForEdit)} color="primary">Enregistrer</Button>
                </DialogActions>
            </Dialog>
        </>
    );


}

export default ManageProductsPage;
