import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://backend.csibon.app/partner_users/register', { username, email, password });
      console.log(response.data);
      // vous pouvez rediriger l'utilisateur vers la page de login après l'inscription réussie
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      background: '#f8f8f8'
    }}>
      <form onSubmit={handleSubmit} style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '20px',
        width: '300px',
        background: '#fff',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
      }}>
        <label>
          Nom d'utilisateur:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required style={{
            margin: '10px 0',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }} />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={{
            margin: '10px 0',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }} />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required style={{
            margin: '10px 0',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }} />
        </label>
        <button type="submit" style={{
          padding: '10px',
          cursor: 'pointer',
          borderRadius: '4px',
          border: 'none',
          color: '#fff',
          background: '#007bff',
          marginBottom: '10px'
        }}>Register</button>
        <Link to="/" style={{ textAlign: 'center', color: '#007bff' }}>Se connecter</Link>
      </form>
    </div>
  );
}

export default Register;
