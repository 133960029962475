import React, { useEffect , useState } from 'react';

import { makeStyles, Button, TextField, Typography, Paper, Container, IconButton, List, ListItem } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import AdminNavbar from './AdminNavbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(3),
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    nestedInput: {
      marginLeft: theme.spacing(4),
    },
    addButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      alignSelf: 'flex-start',
      marginLeft: theme.spacing(4),
    },
    categoryDisplay: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.default,
    },
    subCategoryDisplay: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      borderLeft: `2px solid ${theme.palette.primary.light}`,
    },
  }));
  

function ManageDrinkCategories() {
    const classes = useStyles();
    const [categoryForm, setCategoryForm] = useState({ name: '', subCategories: [] });
    const [displayedCategories, setDisplayedCategories] = useState([]);

    

    const [editingCategory, setEditingCategory] = useState(null);
    const [editedCategoryName, setEditedCategoryName] = useState('');

    const [editingSubCategory, setEditingSubCategory] = useState(null);
    const [editedSubCategoryName, setEditedSubCategoryName] = useState('');


    // États pour la gestion de l'édition
   
   

    const { establishmentId } = useParams();


    const navigate = useNavigate();

    
  useEffect(() => {
    fetchCategories();
  }, []);
  

  const handleCategoryNameChange = (value, path = []) => {
    const updateSubCategories = (subCategories, path) => {
      if (path.length === 0) {
        return { ...subCategories, name: value };
      }
      const [currentPath, ...remainingPath] = path;
      return { ...subCategories, subCategories: subCategories.subCategories.map((sub, index) => index === currentPath ? updateSubCategories(sub, remainingPath) : sub) };
    };
    setCategoryForm(updateSubCategories(categoryForm, path));
  };

  const handleAddSubCategory = (path = []) => {
    const updateSubCategories = (subCategories, path) => {
      if (path.length === 0) {
        return { ...subCategories, subCategories: [...subCategories.subCategories, { name: '', subCategories: [] }] };
      }
      const [currentPath, ...remainingPath] = path;
      return { ...subCategories, subCategories: subCategories.subCategories.map((sub, index) => index === currentPath ? updateSubCategories(sub, remainingPath) : sub) };
    };
    setCategoryForm(updateSubCategories(categoryForm, path));
  };
//   const handleSubmit = () => {
//     setDisplayedCategories([...displayedCategories, categoryForm]);
//     setCategoryForm({ name: '', subCategories: [] }); // Reset form
//   };





const handleSubmit = async () => {
  try {
    console.log('https://backoffice.gourdefriendly.green/create_drinks_establishment/categorie')
    console.log("test5" , JSON.stringify(categoryForm))
    // Envoi de la catégorie principale
    const response = await fetch('https://backend.csibon.app/create_drinks_establishment/categories', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categoryForm),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    const categoryId = responseData.id_categorie;

    console.log(`https://backoffice.gourdefriendly.green/create_drinks_establishment/establishment/${establishmentId}/categories`)
    console.log({ category_id: categoryId })



    // Associer la catégorie à l'établissement
    await fetch(`https://backend.csibon.app/create_drinks_establishment/establishment/${establishmentId}/categories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ category_id: categoryId }),
    });

    // Mise à jour de l'affichage
    // setDisplayedCategories([...displayedCategories, categoryForm]);
    fetchCategories()
    setCategoryForm({ name: '', subCategories: [] });

  } catch (error) {
    console.error('Erreur lors de la soumission du formulaire:', error);
  }
};

const fetchCategories = async () => {
  console.log(`https://backoffice.gourdefriendly.green/create_drinks_establishment/establishment/${establishmentId}/categories`)
    try {
      const response = await fetch(`https://backend.csibon.app/create_drinks_establishment/establishment/${establishmentId}/categories`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const categories = await response.json();
      setDisplayedCategories(categories); // Mise à jour de l'état avec les catégories récupérées
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
        console.log(`https://backoffice.gourdefriendly.green/create_drinks_establishment/establishment/${establishmentId}/categories/${categoryId}/delete`)
      const response = await fetch(`https://backend.csibon.app/create_drinks_establishment/establishment/${establishmentId}/categories/${categoryId}/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Supprimer la catégorie de l'état pour mettre à jour l'interface utilisateur
      setDisplayedCategories(displayedCategories.filter(category => category.id_categorie !== categoryId));
  
    } catch (error) {
      console.error('Erreur lors de la suppression de la catégorie:', error);
    }
  };






  



// modif

const handleEditCategory = (category) => {
  setEditingCategory(category);
  setEditedCategoryName(category.name);
};

const handleEditChange = (e) => {
  setEditedCategoryName(e.target.value);
};

const cancelEditing = () => {
  setEditingCategory(null);
  setEditedCategoryName('');
};


const handleEditSubCategory = (subCategory) => {
  setEditingSubCategory(subCategory);
  setEditedSubCategoryName(subCategory.name);
};

const handleEditSubCategoryChange = (e) => {
  setEditedSubCategoryName(e.target.value);
};

const cancelSubCategoryEditing = () => {
  setEditingSubCategory(null);
  setEditedSubCategoryName('');
};

const handleUpdateSubCategory = async (parentCategoryId, subCategoryId) => {
  try {
  
    const response = await fetch(`https://backend.csibon.app/create_drinks_establishment/subcategories/${subCategoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: editedSubCategoryName }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Mise à jour réussie, recharger les catégories
    fetchCategories();
    setEditingSubCategory(null);
    setEditedSubCategoryName('');
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la sous-catégorie:', error);
  }
};


const handleUpdateCategory = async () => {
  if (editingCategory) {
    try {
      const response = await fetch(`https://backend.csibon.app/create_drinks_establishment/categories/${editingCategory.id_categorie}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: editedCategoryName }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Mise à jour réussie, recharger les catégories
      fetchCategories();
      setEditingCategory(null);
      setEditedCategoryName('');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la catégorie:', error);
    }
  }
};

const handleDeleteSubCategory = async (subCategoryId) => {
  try {
    console.log(`https://backoffice.gourdefriendly.green/create_drinks_establishment/subcategories/${subCategoryId}`)
    const response = await fetch(`https://backend.csibon.app/create_drinks_establishment/subcategories/${subCategoryId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }

    // Suppression réussie, recharger les catégories
    fetchCategories();
  } catch (error) {
    alert(error.message); // Affichez l'erreur à l'utilisateur
    console.error('Erreur lors de la suppression de la sous-catégorie:', error);
  }
};












  const [newSubCategoryName , setNewSubCategoryName] = useState("")
  const handleAddNewSubCategory = async (parentCategoryId, newSubCategoryName) => {
    if (!newSubCategoryName.trim()) {
      alert("Le nom de la sous-catégorie ne peut pas être vide.");
      return;
    }
  
    try {
      const response = await fetch(`https://backend.csibon.app/create_drinks_establishment/categories/${parentCategoryId}/subcategories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newSubCategoryName }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }
  
      // Ajout réussi, recharger les catégories
      await fetchCategories();
      setNewSubCategoryName(''); // Réinitialiser le champ après l'ajout
    } catch (error) {
      alert(error.message); // Afficher l'erreur à l'utilisateur
      console.error('Erreur lors de l\'ajout de la nouvelle sous-catégorie:', error);
    }
  };
  
  const [addingSubCategoryId, setAddingSubCategoryId] = useState(null);

const renderAddNewSubCategoryInput = (parentCategoryId) => {
  if (addingSubCategoryId === parentCategoryId) {
    return (
      <div>
        <TextField
          label="Nom de la nouvelle sous-catégorie"
          value={newSubCategoryName}
          onChange={(e) => setNewSubCategoryName(e.target.value)}
          margin="normal"
        />
        <IconButton
          edge="end"
          aria-label="validate"
          onClick={() => {
            handleAddNewSubCategory(parentCategoryId, newSubCategoryName);
            setAddingSubCategoryId(null); // Reset l'état après l'ajout
          }}
        >
          <CheckIcon />
        </IconButton>
      </div>
    );
  }
  return null;
};

  
const renderSubCategories = (subCategories, level = 1, parentCategoryId) => {
  return subCategories.map((subCategory, index) => (
    <div key={subCategory.id_categorie} className={classes.subCategoryDisplay}>
      {editingSubCategory && editingSubCategory.id_categorie === subCategory.id_categorie ? (
        // Formulaire d'édition de la sous-catégorie
        <>
          <TextField
            value={editedSubCategoryName}
            onChange={handleEditSubCategoryChange}
          />
          <IconButton onClick={() => handleUpdateSubCategory(parentCategoryId, subCategory.id_categorie)}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={cancelSubCategoryEditing}>
            <CancelIcon />
          </IconButton>
        </>
      ) : (
        // Affichage de la sous-catégorie avec les options de modification et suppression
        <>
          <Typography variant="body2">{`Sous-catégorie niveau ${level}: ${subCategory.name}`}</Typography>
          <IconButton onClick={() => handleEditSubCategory(subCategory)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteSubCategory(subCategory.id_categorie)}>
            <DeleteIcon />
          </IconButton>
          {/* Bouton pour ajouter une sous-catégorie */}
          {subCategory.subCategories && subCategory.subCategories.length === 0 && (
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => setAddingSubCategoryId(subCategory.id_categorie)}
              className={classes.addButton}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}
        </>
      )}
      {/* Afficher le champ de saisie lors de l'ajout d'une nouvelle sous-catégorie */}
      {addingSubCategoryId === subCategory.id_categorie && (
        <div>
          <TextField
            label={`Nom de la nouvelle sous-catégorie niveau ${level + 1}`}
            value={newSubCategoryName}
            onChange={(e) => setNewSubCategoryName(e.target.value)}
            margin="normal"
          />
          <IconButton
            edge="end"
            aria-label="validate"
            onClick={() => {
              handleAddNewSubCategory(subCategory.id_categorie, newSubCategoryName);
              setAddingSubCategoryId(null); // Réinitialiser après l'ajout
              setNewSubCategoryName(''); // Réinitialiser le nom de la nouvelle sous-catégorie
            }}
          >
            <CheckIcon />
          </IconButton>
        </div>
      )}
      {/* Récursivité pour les sous-sous-catégories, si elles existent */}
      {subCategory.subCategories && renderSubCategories(subCategory.subCategories, level + 1, subCategory.id_categorie)}
    </div>
  ));
};


  const renderCategoryInput = (subCategory, path) => (
    <>
      <TextField
        label={path.length === 0 ? "Nom de la catégorie" : `Sous-catégorie niveau ${path.length}`}
        value={subCategory.name}
        onChange={(e) => handleCategoryNameChange(e.target.value, path)}
        className={classes.nestedInput}
      />
      <IconButton
        edge="end"
        aria-label="add"
        onClick={() => handleAddSubCategory(path)}
        className={classes.addButton}
      >
        <AddCircleOutlineIcon />
      </IconButton>
      {subCategory.subCategories.map((sub, index) => renderCategoryInput(sub, [...path, index]))}

      
    </>
  );



  


  return (
    <>
      <AdminNavbar establishmentId={establishmentId} name={""} />
      <IconButton onClick={() => navigate(`/accueil`)} color="primary" aria-label="retour">
        <ArrowBackIcon />
      </IconButton>
      <Container maxWidth="sm">
        <Paper className={classes.container}>
        <Typography variant="h5">Créer une catégorie de boissons</Typography>
         <div className={classes.formControl}>
           {renderCategoryInput(categoryForm, [])}
           <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          créer la nouvelle catégorie
        </Button>
        </div>
      
          <Typography variant="h5">Gérer les catégories de boissons</Typography>
          
          {displayedCategories.map((category, index) => (
            <div key={index} className={classes.categoryDisplay}>
              {editingCategory && editingCategory.id_categorie === category.id_categorie ? (
                <>
                  <TextField
                    value={editedCategoryName}
                    onChange={handleEditChange}
                  />
                  <IconButton onClick={handleUpdateCategory}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton onClick={cancelEditing}>
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" style={{ flexGrow: 1 }}>
                    {category.name}
                  </Typography>
                  <IconButton onClick={() => handleEditCategory(category)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteCategory(category.id_categorie)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
                           {renderSubCategories(category.subCategories)}

            </div>
          ))}
        </Paper>
      </Container>
    </>
  );

  
}

export default ManageDrinkCategories;


