import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://backend.csibon.app/partner_users/login', { email, password });
      const { access_token , refresh_token ,user_id , admin } = response.data;

      // Stocker le token dans le local storage
      localStorage.setItem('token', access_token);
      localStorage.setItem('refresh_token', refresh_token )
    
      localStorage.setItem('user_id', user_id); // Assurez-vous que 'user_id' est présent dans le token

      localStorage.setItem('admin', admin);
      

      // Rediriger l'utilisateur vers une nouvelle page
      navigate('/accueil');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      background: '#f8f8f8'
    }}>
      <form onSubmit={handleSubmit} style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '40px',
        width: '300px',
        background: '#fff',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
      }}>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={{
            margin: '10px 0',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }} />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required style={{
            margin: '10px 0',
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }} />
        </label>
        <button type="submit" style={{
          padding: '10px',
          cursor: 'pointer',
          borderRadius: '4px',
          border: 'none',
          color: '#fff',
          background: '#007bff',
          marginBottom: '10px'
        }}>Login</button>
        <Link to="/register" style={{ textAlign: 'center', color: '#007bff' }}>Créer un compte</Link>
      </form>
    </div>
  );
}

export default Login;
