import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../components/Navbar';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddEntreprisePage() {
    const [nomEntreprise, setNomEntreprise] = useState('');
    const [codeEntreprise, setCodeEntreprise] = useState('');
    const [adresse, setAdresse] = useState('');
    const [entreprises, setEntreprises] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [modifications, setModifications] = useState({});



    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = 'https://backend.csibon.app/entreprise/add';
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nom_entreprise: nomEntreprise,
                    code_entreprise: codeEntreprise,
                    adresse: adresse,
                }),
            });
    
            if (response.ok) {
                const newEntreprise = await response.json(); // Supposons que cela renvoie la nouvelle entreprise
                console.log(newEntreprise)
                toast.success('Entreprise ajoutée avec succès');
                setEntreprises([...entreprises, newEntreprise.entreprise]); // Ajouter la nouvelle entreprise à l'état
            } else {
                toast.error('Erreur lors de l\'ajout de l\'entreprise');
            }
        } catch (error) {
            toast.error('Erreur lors de l\'envoi des données', error);
        }
    };
    

    const handleInputChange = (id, field, value) => {
        setModifications({
            ...modifications,
            [id]: {
                ...modifications[id],
                [field]: value,
            },
        });
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://backend.csibon.app/entreprise/delete/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ suppr: true }),
            });
    
            if (response.ok) {
                toast.success('Entreprise supprimée avec succès');

                // Mise à jour de l'état pour retirer l'entreprise de l'affichage
                setEntreprises(entreprises.filter((entreprise) => entreprise.id !== id));
            } else {
                toast.error('Erreur lors de la suppression de l\'entreprise');
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la demande de suppression', error);
        }
    };

    const handleSave = async (id) => {
        const modifiedData = modifications[id];
    
        try {
            const response = await fetch(`https://backend.csibon.app/entreprise/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(modifiedData),
            });
    
            if (response.ok) {
                toast.success('Informations de l\'entreprise mises à jour');
                // Mettre à jour l'état des entreprises avec les nouvelles données
                setEntreprises(entreprises.map((entreprise) => {
                    if (entreprise.id === id) {
                        return { ...entreprise, ...modifiedData };
                    }
                    return entreprise;
                }));
                // Réinitialiser les modifications pour cette entreprise
                setModifications(modifications => {
                    const newModifications = { ...modifications };
                    delete newModifications[id];
                    return newModifications;
                });
            } else {
                toast.error('Erreur lors de la mise à jour de l\'entreprise');
            }
        } catch (error) {
            toast.error('Erreur lors de l\'envoi des données', error);
        }
    };
    

    useEffect(() => {
        const fetchEntreprises = async () => {
            try {
                const response = await fetch('https://backend.csibon.app/entreprise/all');
                if (response.ok) {
                    const data = await response.json();
                    setEntreprises(data);
                } else {
                    console.error('Erreur lors de la récupération des entreprises');
                }
            } catch (error) {
                console.error('Erreur lors de la demande', error);
            }
        };

        fetchEntreprises();
    }, []);


    return (
        <>
            <Navbar />
            <ToastContainer />
            <div className="container my-5">
                <h1 className="text-center mb-4">Ajouter une Entreprise</h1>
                <form onSubmit={handleSubmit} className="w-50 mx-auto">
                    <div className="mb-3">
                        <label htmlFor="nomEntreprise" className="form-label">Nom de l'Entreprise</label>
                        <input type="text" className="form-control" id="nomEntreprise" value={nomEntreprise} onChange={(e) => setNomEntreprise(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="codeEntreprise" className="form-label">Code de l'Entreprise</label>
                        <input type="text" className="form-control" id="codeEntreprise" value={codeEntreprise} onChange={(e) => setCodeEntreprise(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="adresse" className="form-label">Adresse</label>
                        <input type="text" className="form-control" id="adresse" value={adresse} onChange={(e) => setAdresse(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary">Ajouter</button>
                </form>


                {/* Affichage des entreprises */}
                <h2 className="text-center mb-4">Liste des Entreprises</h2>
                <div className="mb-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Rechercher par nom d'entreprise"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Code</th>
                            <th>Adresse</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entreprises
        .filter(entreprise => {
            if (searchTerm === "") {
                return true; // Affiche toutes les entreprises si searchTerm est vide
            }
            return entreprise.nom_entreprise.toLowerCase().includes(searchTerm.toLowerCase());
        }).map((entreprise) => {
                            const modif = modifications[entreprise.id] || entreprise;

                            return (
                                <tr key={entreprise.id}>
                                    <td>
                                        <input
                                            type="text"
                                            value={modif.nom_entreprise}
                                            onChange={(e) => handleInputChange(entreprise.id, 'nom_entreprise', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={modif.code_entreprise}
                                            onChange={(e) => handleInputChange(entreprise.id, 'code_entreprise', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={modif.adresse}
                                            onChange={(e) => handleInputChange(entreprise.id, 'adresse', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleSave(entreprise.id)} className="btn btn-primary ms-2">Sauvegarder</button>
                                        {/* <button onClick={() => handleDelete(entreprise.id)} className="btn btn-danger ms-2">Supprimer</button> */}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>
            </div>
        </>
    );
}

export default AddEntreprisePage;
