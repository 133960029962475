
import React, { useState, useEffect } from 'react';
import axios from 'axios';  // Assurez-vous d'avoir installé axios
import styled from 'styled-components';
import axiosInstance from '../../../services/axiosInstance';

const FormWrapper = styled.div`
    width: 80%;
    margin: 2rem auto;
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

const FormField = styled.div`
    margin-bottom: 1rem;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
`;

const SubmitButton = styled.button`
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }
`;

const CategoryImage = styled.img`
    max-width: 150px;   // Largeur maximale
    max-height: 150px;  // Hauteur maximale
    margin: 10px;       // Marge autour de l'image
    border-radius: 8px; // Coins arrondis pour une belle apparence
`;


const CreateCategoryForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        image_url: ''
    });
    const [categories, setCategories] = useState([]);
    const [editingCategory, setEditingCategory] = useState(null);
    const [parentCategoryId, setParentCategoryId] = useState(null);

    const handleChangeParentCategory = (e) => {
        setParentCategoryId(e.target.value);
    }

    const startEditing = (category) => {
        setFormData({
            name: category.name,
            description: category.description,
            // ... autres champs
        });
        setEditingCategory(category);
    }





    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleFileChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            image: e.target.files[0]
        }));
    };





    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = new FormData();
        dataToSend.append('name', formData.name);
        dataToSend.append('description', formData.description);
        if (formData.image) {
            dataToSend.append('image', formData.image);
        }
        if (parentCategoryId) {
            dataToSend.append('parentCategoryId', parentCategoryId);
        }

        // try {
        //     if (editingCategory) {
        //         const response = await axios.put(`https://backoffice.gourdefriendly.green/drinks/drink-categories/${editingCategory.id}`, dataToSend);
        //         if (response.status === 200) {
        //             console.log(response)
        //             alert('Catégorie mise à jour avec succès!');
        //         }
        //     } else {
        //         try {
        //             const response = await axios.post('https://backoffice.gourdefriendly.green/drinks/drink-categories', dataToSend);
        //             if (response.status === 201) {
        //                 console.log(response)
        //                 alert('Catégorie créée avec succès!');
        //                 onClose(); // Ferme la fenêtre modale
        //             }
        //         } catch (error) {
        //             console.error("Erreur lors de la création de la catégorie", error);
        //             alert('Erreur lors de la création de la catégorie');
        //         }
        //     }
        // } catch (error) {
        //     console.error("Erreur lors de la mise à jour de la catégorie", error);
        //     alert('Erreur lors de la mise à jour de la catégorie');
        // }

        try {
            if (editingCategory) {
                const response = await axiosInstance.put(`/drinks/drink-categories/${editingCategory.id}`, dataToSend);
                if (response.status === 200) {
                    console.log(response);
                    alert('Catégorie mise à jour avec succès!');
                }
            } else {
                const response = await axiosInstance.post('/drinks/drink-categories', dataToSend);
                if (response.status === 201) {
                    console.log(response);
                    alert('Catégorie créée avec succès!');
                    onClose(); // Ferme la fenêtre modale
                }
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour ou de la création de la catégorie", error);
            alert('Erreur lors de la mise à jour ou de la création de la catégorie');
        }
    }


    // useEffect(() => {
    //     const fetchCategories = async () => {
    //         try {
    //             const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/drink-categories');
    //             setCategories(response.data);
    //         } catch (error) {
    //             console.error('Erreur lors de la récupération des catégories', error);
    //         }
    //     };

    //     fetchCategories();
    // }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/drinks/drink-categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories', error);
            }
        };
    
        fetchCategories();
    }, []);

    return (

        <FormWrapper>
            <Title>{editingCategory ? "Modifier la catégorie existante" : "Créer une nouvelle catégorie"}</Title>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Label>Nom:</Label>
                    <Input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </FormField>
                <FormField>
                    <Label>Description:</Label>
                    <Textarea name="description" value={formData.description} onChange={handleChange}></Textarea>
                </FormField>
                {/* <FormField>
                    <Label>URL de l'image:</Label>
                    <Input type="url" name="image_url" value={formData.image_url} onChange={handleChange} />
                </FormField> */}
                <FormField>
                    <Label>Image de la catégorie:</Label>
                    <Input type="file" onChange={handleFileChange} />
                </FormField>
                <FormField>
                    <Label>Catégorie parente:</Label>
                    <select name="parentCategoryId" value={parentCategoryId} onChange={handleChangeParentCategory}>
                        <option value="">Aucune</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </FormField>
                <FormField>
                    <SubmitButton type="submit">{editingCategory ? "Mettre à jour la catégorie" : "Ajouter la catégorie"}</SubmitButton>
                </FormField>
            </form>

            {editingCategory && <button onClick={() => setEditingCategory(null)}>Annuler la modification</button>}


            <h3>Catégories ajoutées :</h3>
            <ul>
                {categories.map(category => (
                    <li key={category.id}>
                        <CategoryImage src={`https://backend.csibon.app/static/drink_images/${category.image_url}`} alt={category.name} />

                        {category.name}
                        <button onClick={() => startEditing(category)}>Modifier</button>
                    </li>
                ))}
            </ul>

        </FormWrapper>
    );
}



export default CreateCategoryForm