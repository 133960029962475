import React, { useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';


function AddSupplier() {
    const [name, setName] = useState('');
    const [contactDetails, setContactDetails] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // axios.post('https://backoffice.gourdefriendly.green/stock/suppliers', {
        //     name: name,
        //     contact_details: contactDetails
        // })
         axiosInstance.post('https://backend.csibon.app/stock/suppliers', {
            name: name,
            contact_details: contactDetails
        })
        .then(response => {
            console.log('Fournisseur ajouté avec succès!', response.data);
            // Vous pouvez ajouter des logiques supplémentaires ici, comme actualiser la liste des fournisseurs
        })
        .catch(error => {
            console.error('Erreur lors de l\'ajout du fournisseur:', error);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nom du fournisseur:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div>
                    <label>Détails du contact:</label>
                    <textarea value={contactDetails} onChange={(e) => setContactDetails(e.target.value)} required></textarea>
                </div>
                <div>
                    <button type="submit">Ajouter un fournisseur</button>
                </div>
            </form>
        </div>
    );
}

export default AddSupplier;
