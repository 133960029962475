import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Button, makeStyles, Typography, Paper, Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showSuccess = (message) => {
    toast.success(message);
};

// Affiche une notification d'erreur
const showError = (message) => {
    toast.error(message);
};

const useStyles = makeStyles(theme => ({
    formContainer: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    display: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: "80%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    productCard: {
        cursor: 'pointer',
        transition: '0.3s',
        '&:hover': {
            boxShadow: theme.shadows[10],
        },
    },
    selectedProductCard: {
        backgroundColor: theme.palette.action.selected,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    productImage: {
        width: '100%',
        height: 'auto',
        maxHeight: 150,
    },
    navigationButtons: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
    formField: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    button: {
        margin: theme.spacing(1),
    },

    productImage: {
        maxWidth: '100%',
        maxHeight: 150,
        objectFit: 'contain', // Cette propriété garde le ratio de l'image
    },
    
}));

function ProductSelectionForm() {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [isHidden, setIsHidden] = useState(false);
    const classes = useStyles();
    const productsPerPage = 4;
    const [establishmentProducts, setEstablishmentProducts] = useState([]);
    const [productEdits, setProductEdits] = useState({}); // { [productId]: { nb_stock: number, is_hidden: boolean }}


    const { establishmentId } = useParams();

    useEffect(() => {
        axios.get('https://backend.csibon.app/shop/products')
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('Error fetching products: ', error);
            });
    }, []);

    const displayedProducts = products.slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage);

    const handleProductSelect = (productId) => {
        setSelectedProductId(productId);
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage => Math.min(currentPage + 1, Math.ceil(products.length / productsPerPage) - 1));
    };

    const handleIsHiddenChange = (event) => {
        setIsHidden(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!selectedProductId || !quantity) {
            alert('Veuillez sélectionner un produit et indiquer la quantité.');
            return;
        }
    
        const productData = {
            establishment_id: establishmentId,
            productshop_id: selectedProductId,
            nb_stock: quantity,
            is_hidden: isHidden,
        };

        console.log(productData)
    
        axios.post('https://backend.csibon.app/shop/establishment/add_product', productData)
            .then(response => {
                console.log(response.data);
                // alert('Produit ajouté avec succès!');
                showSuccess('Produit ajouté avec succès!');
            })
            .catch(error => {
                console.error('Erreur lors de l\'ajout du produit: ', error);
                // alert('Erreur lors de l\'ajout du produit.');
                showError('Erreur lors de l\'ajout du produit.');

            });
    };

    useEffect(() => {
        // ... Votre appel axios existant pour récupérer tous les produits ...
    
        // Nouvel appel axios pour récupérer les produits de l'établissement
        axios.get(`https://backend.csibon.app/shop/establishment/${establishmentId}/products`)
            .then(response => {
                setEstablishmentProducts(response.data);
             
            })
            .catch(error => {
                console.error('Error fetching establishment products: ', error);
             

            });
    }, [establishmentId]); // Ajout de establishmentId comme dépendance




    // changement

    const handleStockChange = (productId, newStock) => {
        setProductEdits(prev => ({ ...prev, [productId]: { ...prev[productId], nb_stock: newStock } }));
    };
    
    const handleVisibilityChange = (productId, newVisibility) => {
        setProductEdits(prev => ({ ...prev, [productId]: { ...prev[productId], is_hidden: newVisibility } }));
    };
    
    const handleSubmitChanges = (productId) => {
        const productData = productEdits[productId];

        if(productData){
   
        // API call to update the product
        axios.put(`https://backend.csibon.app/shop/establishment/${establishmentId}/products/${productId}`, productData)
            .then(response => {
                // Handle success response
                // You may also want to update the establishmentProducts state here to reflect the changes
                showSuccess('Produit modifié avec succès!');

            })
            .catch(error => {
                console.error('Erreur lors de la modification du produit: ', error);
                // alert('Erreur lors de la modification du produit.');
                showError('Erreur lors de la modification du produit.');

            });

        }
        else{
            showError("Vous n'avez effectué aucun changemement");

        }
    };

    // delete  

    const handleDeleteProduct = (productId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?")) {
            axios.delete(`https://backend.csibon.app/shop/establishment/${establishmentId}/products/${productId}`)
                .then(response => {
                    showSuccess('Produit supprimé avec succès!');
                    setEstablishmentProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
                })
                .catch(error => {
                    console.error('Erreur lors de la suppression du produit: ', error);
                    showError('Erreur lors de la suppression du produit.');
                });
        }
    };
    
    
    return (

        <>
        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Paper className={classes.formContainer}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {displayedProducts.map(product => (
                        <Grid item xs={12} sm={6} md={3} key={product.id}>
                            <Card 
                                className={`${classes.productCard} ${product.id === selectedProductId ? classes.selectedProductCard : ''}`}
                                onClick={() => handleProductSelect(product.id)}
                            >
                                <CardContent className={classes.cardContent}>
                                    <img 
                                        src={`https://backend.csibon.app/static/container_images/${product.image_url}`} 
                                        alt={product.name}
                                        className={classes.productImage}
                                    />
                                    <Typography variant="h6">{product.name}</Typography>
                                    <Typography variant="subtitle1">Prix: {product.price} €</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.navigationButtons}>
                    <Button onClick={handlePrevPage} disabled={currentPage === 0}>Précédent</Button>
                    <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(products.length / productsPerPage) - 1}>Suivant</Button>
                </div>
                <div className={classes.formField}>
                    <TextField
                        label="Quantité"
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        fullWidth
                    />
                </div>
                <div className={classes.formField}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isHidden}
                                onChange={handleIsHiddenChange}
                                name="isHidden"
                                color="primary"
                            />
                        }
                        label="Masquer le produit"
                    />
                </div>
                <div className={classes.formField}>
                    <Button type="submit" variant="contained" color="primary">
                        Soumettre
                    </Button>
                
                </div>
            </form>
        </Paper>

     
        <Grid container spacing={2} className={classes.display} >
    {establishmentProducts.map(product => (
        <Grid item xs={12} sm={6} md={3} key={product.id}>
            <Card className={classes.productCard}>
                <CardContent className={classes.cardContent}>
                    <img 
                        src={`https://backend.csibon.app/static/container_images/${product.image_url}`} 
                        alt={product.name}
                        className={classes.productImage}
                    />
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography variant="subtitle1">Prix: {product.price} €</Typography>
                    {/* <Typography variant="subtitle2">Stock: {product.nb_stock}</Typography>
                    <Typography variant="subtitle2">
                        Visibilité: {product.is_hidden ? 'Caché' : 'Visible'}
                    </Typography> */}

                    <TextField
                        label="Stock"
                        type="number"
                        value={productEdits[product.id]?.nb_stock ?? product.nb_stock}
                        onChange={(e) => handleStockChange(product.id, e.target.value)}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={productEdits[product.id]?.is_hidden ?? product.is_hidden}
                                onChange={(e) => handleVisibilityChange(product.id, e.target.checked)}
                                name="isHidden"
                                color="primary"
                            />
                        }
                        label="Masquer le produit"
                    />
                     <Button onClick={() => handleSubmitChanges(product.id)} variant="contained" color="primary">
                        Modifier
                    </Button>
                    <Button style={{marginTop : 10}} onClick={() => handleDeleteProduct(product.id)} variant="contained" color="secondary">
                        Supprimer
                    </Button>
                </CardContent>
            </Card>
        </Grid>
    ))}
</Grid>

</>
    );
}

export default ProductSelectionForm;
