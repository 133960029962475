import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardActions, CardMedia, CardActionArea, Button, Grid, TextField, Dialog, AppBar, Toolbar, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // assurez-vous d'installer axios avec `npm install axios`
import { Link as RouterLink } from 'react-router-dom';
import AdminNavbar from '../../components/AdminNavbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EstablishmentLocation from '../../components/EstablishmentLocation';
import EstablishmentHours from '../../components/EstablishmentHours';
import EstablishmentSocialMedia from '../../components/EstablishmentSocialMedia';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axiosInstance from '../../services/axiosInstance';
import AccessDeniedMessage from '../../components/AccessDeniedMessage';
import { Switch, FormControlLabel , Paper, Box } from '@material-ui/core';
import { CheckCircleOutline, CancelOutlined } from '@material-ui/icons';
import { Navigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: "2rem"
  },
  input: {
    margin: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    maxWidth: 400,
  },

  photoCard: {
    width: 400,  // Largeur fixe pour le conteneur de la carte
    margin: theme.spacing(2),
},
media: {
    height: 320,
    width: '100%',  // La largeur de l'image sera maintenant celle du conteneur de la carte
    objectFit: 'cover',
    margin: '0 auto',  // Centrer l'image horizontalement
},
photoContainer: {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: theme.spacing(2),
},
buttonContainer: {
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),  // Espacement entre les boutons
  padding: theme.spacing(2, 0),
},
buttonSpacing: {
  marginRight: theme.spacing(2),  // Ajoutez cette classe au bouton "Upload Photos"
},





clocheSection: {
  padding: theme.spacing(3),
  margin: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  border: `2px solid ${theme.palette.primary.main}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
},
clocheTitle: {
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(2),
},
switchLabel: {
  display: 'flex',
  alignItems: 'center',
},
// icon: {
//   marginRight: theme.spacing(1),
// },
// checkedIcon: {
//   color: theme.palette.success.main,
// },
// uncheckedIcon: {
//   color: theme.palette.error.main,
// },


}));



// En-tête de la page d'administration
function EstablishmentHeader({ name, onNameChange }) {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Nom de l'établissement
      </Typography>
      <TextField
        value={name}
        onChange={e => onNameChange(e.target.value)}
        label="Nom"
        variant="outlined"
        fullWidth
        className={classes.input}
      />
      
    </div>
  );
}

// Description de l'établissement
function EstablishmentDescription({ description, onDescriptionChange , sethasAccess}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Description de l'établissement
      </Typography>
      <TextField
        value={description}
        onChange={e => onDescriptionChange(e.target.value)}
        label="Description"
        multiline
        variant="outlined"
        fullWidth
        className={classes.input}
      />
    </div>
  );
}








// async function fetchEstablishment(id) {
//   try {
//     const res = await axios.get(`https://backoffice.gourdefriendly.green/establishments/${id}`);
//     return res.data.establishment;
//   } catch (err) {
//     console.error(err);
//     return null;
//   }
// }

async function fetchEstablishment(id) {
  try {
    const res = await axiosInstance.get(`/establishments/${id}`);
    return res.data.establishment;
  } catch (err) {
    // Vérifiez si l'erreur provient d'une réponse HTTP et contient un message d'erreur
    if (err.response && err.response.data && err.response.data.error) {
      // alert(err.response.data.error); // Affiche le message d'erreur
      return false
      
    } else {
      alert("Une erreur est survenue lors de la récupération de l'établissement.");
    }
    console.error(err);
    return null;
  }
}

// async function updateEstablishment(partnerUserId, establishment) {
//   console.log(establishment)
//   try {
//     const res = await axios.patch(`https://backoffice.gourdefriendly.green/partner_users/${partnerUserId}/establishments/${establishment.id}`, establishment);
//     return res.data.establishment;
//   } catch (err) {
//     console.error(err);
//     return null;
//   }
// }


async function updateEstablishment(partnerUserId, establishment) {
  try {
    console.log(partnerUserId)
    const res = await axiosInstance.patch(`/partner_users/${partnerUserId}/establishments/${establishment.id}`, establishment);
    return res.data.establishment;
  } catch (err) {
    console.error(err);
    return null;
  }
}


function AdminPage() {
  
  const classes = useStyles();
 

  const { id_etablissement } = useParams();
  const [establishment, setEstablishment] = useState(null);
  const navigate = useNavigate(); // Ajoutez ceci
  const [photos, setPhotos] = useState([]); // pour stocker les photos récupérées


  const [previewPhotos, setPreviewPhotos] = useState([]); // État pour stocker les URL de prévisualisation


  // const partnerUserId = process.env.REACT_APP_PARTNER_USER_ID; // Ajoutez ceci
  const partnerUserId = localStorage.getItem('user_id')
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentPhotoId, setCurrentPhotoId] = useState(null); 
  const [newPhotoFile, setNewPhotoFile] = useState(null);  // Pour stocker le nouveau fichier choisi pour la modification

  const fileInput = useRef(null);
  const [hasAccess , sethasAccess] = useState(true)
  const [offeredDrinks, setOfferedDrinks] = useState(establishment?.number_of_offered_products|| 0);


  const handleOfferedDrinksChange = (newOfferedDrinks) => {

    const updatedEstablishment = { ...establishment, number_of_offered_products: newOfferedDrinks };
    setEstablishment(updatedEstablishment);
    setOfferedDrinks(newOfferedDrinks);
    updateEstablishment(partnerUserId, updatedEstablishment);
  };
  

 

  const handleUploadPhotos = async () => {
    const files = fileInput.current.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
    }

    try {
        const response = await axiosInstance({
            method: 'post',
            url: `/etablissement/${id_etablissement}/photos`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log(response);
        // Logique après l'envoi réussi
    } catch (error) {
        console.error('Error adding data: ', error);
    }

    // Réinitialisez la prévisualisation après l'envoi
    setPreviewPhotos([]);
};

  const handleNewPhotoFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPhotoFile(file);
    }
  };

  const handleFileChange = (event) => {
    console.log("handleFileChange called");  // Ajout d'un log pour le débogage
    const files = event.target.files;
    const photosArray = [];

    for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();

        reader.onload = (e) => {
            photosArray.push(e.target.result);
            if (photosArray.length === files.length) {
                setPreviewPhotos(photosArray);
            }
        };

        reader.readAsDataURL(files[i]);
    }
}



const handleDeletePhoto = async (photoId) => {
  try {
    await axiosInstance.delete(`/etablissement/${id_etablissement}/photos/${photoId}`);
    setPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== photoId));
  } catch (error) {
    console.error("Error deleting photo:", error);
  }
}

const openEditDialog = (photoId) => {
  setCurrentPhotoId(photoId);
  setEditDialogOpen(true);
}



const handleEditPhoto = async () => {
  if (!currentPhotoId || !newPhotoFile) return;

  const formData = new FormData();
  formData.append('file', newPhotoFile);

  try {
    const response = await axiosInstance.put(`/etablissement/${id_etablissement}/photos/${currentPhotoId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    const updatedPhotoURL = response.data.photo_url;
    setPhotos(prevPhotos => prevPhotos.map(photo => 
      photo.id === currentPhotoId ? { ...photo, photo_url: updatedPhotoURL } : photo
    ));

    setEditDialogOpen(false);
    setNewPhotoFile(null);
  } catch (error) {
    console.error('Error updating photo:', error);
  }
};



  useEffect(() => {
    // fetchEstablishment(id_etablissement).then(setEstablishment);
    
  //   const fetchAndSetEstablishment = async () => {
  //     const establishmentData = await fetchEstablishment(id_etablissement);
  //     if (establishmentData) {
  //         setEstablishment(establishmentData);
  //         sethasAccess(true);
  //     } else {
  //         sethasAccess(false);
  //     }
  // };
  const fetchAndSetEstablishment = async () => {
    const establishmentData = await fetchEstablishment(id_etablissement);
    if (establishmentData) {
    
      setEstablishment(establishmentData);
      setOfferedDrinks(establishmentData.number_of_offered_products || 0);  // Mise à jour initiale
      sethasAccess(true);
    } else {
      sethasAccess(false);
    }
  };
  

  fetchAndSetEstablishment();
  
    axiosInstance.get(`/etablissement/${id_etablissement}/photos`)
      .then(response => {
        setPhotos(response.data);
      })
      .catch(error => {
        console.error("Error fetching photos:", error);
      });
  
  }, [id_etablissement]);
  


  if (!hasAccess) {
    return <AccessDeniedMessage />;
}
  if (!establishment) return <p>Loading...</p>;

  const handleNameChange = newName => {
    const updatedEstablishment = { ...establishment, name: newName };
    setEstablishment(updatedEstablishment);
    updateEstablishment(partnerUserId, updatedEstablishment);
  };

  const handleDescriptionChange = newDescription => {
    const updatedEstablishment = { ...establishment, description: newDescription };
    setEstablishment(updatedEstablishment);
    updateEstablishment(partnerUserId, updatedEstablishment);
  };



  const handleLocationChange = newLocation => {
    console.log(newLocation)
    const updatedEstablishment = { ...establishment, lat: newLocation.lat, lon: newLocation.lon };
    setEstablishment(updatedEstablishment);
    updateEstablishment(partnerUserId, updatedEstablishment);
  };

  const handleHoursChange = newHours => {
    console.log(newHours)
    const updatedEstablishment = { ...establishment, horaire: JSON.stringify(newHours) };
    setEstablishment(updatedEstablishment);
    updateEstablishment(partnerUserId, updatedEstablishment);
  };

  const handleSocialMediaChange = newSocialMedia => {
    const updatedEstablishment = { ...establishment, socialMedia: newSocialMedia };
    setEstablishment(updatedEstablishment);
    updateEstablishment(partnerUserId, updatedEstablishment);
  };


  const toggleVisibility = async (establishment) => {
    try {
        // Mise à jour de la visibilité sur le serveur
        const response = await axiosInstance.put(`/update-visibility_establishment/${establishment.id}`, {
            visible: !establishment.visible
        });

        if (response.status === 200) {
            // Mise à jour de l'état local pour refléter le changement
            setEstablishment(prevEstablishment => ({
              ...prevEstablishment,
              visible: !prevEstablishment.visible
            }));
            
        }
    } catch (error) {
        console.error('Erreur lors de la modification de la visibilité', error);
    }
};

const handleToggleCloche = (checked) => {
  const updatedEstablishment = { ...establishment, la_cloche: checked };
  setEstablishment(updatedEstablishment);
  updateEstablishment(partnerUserId, updatedEstablishment);
};

  return (
    <div>

      <AdminNavbar establishmentId={id_etablissement} name={establishment.name} />


<div >
      <IconButton onClick={() => navigate(`/accueil`)} color="primary" aria-label="retour">
        <ArrowBackIcon />
      </IconButton>
</div>
      <Button
style={{marginLeft : 20 , marginTop : 20 , marginBottom : 20}}
                variant="contained"
                color={establishment.visible ? "secondary" : "primary"}
                onClick={() => toggleVisibility(establishment)}
            >
                {establishment.visible ? "Ne plus rendre visible" : "Rendre visible"}
      </Button>
    


      <Paper className={classes.clocheSection}>
        <Box>
          <Typography variant="h5" className={classes.clocheTitle}>
            Fait-il partie de la cloche ?
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Utilisez ce bouton pour indiquer si l'établissement fait partie de la cloche.
          </Typography>
        </Box>
        
        <FormControlLabel
          control={
            <Switch
              checked={establishment?.la_cloche || false}
              onChange={(e) => handleToggleCloche(e.target.checked)}
              color="primary"
           
              
            />
          }
          label={
            establishment?.la_cloche ? (
              <Typography variant="body1" className={classes.switchLabel}>
                 Fait partie de la cloche
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.switchLabel}>
               Ne fait pas partie de la cloche
              </Typography>
            )
          }
        />
      </Paper>

      <EstablishmentHeader name={establishment.name} onNameChange={handleNameChange} />
    
      <div className={classes.buttonContainer}>
      <input 
        accept="image/*" 
        style={{ display: 'none' }} 
        id="raised-button-file" 
        multiple 
        type="file" 
        ref={fileInput} 
        onChange={handleFileChange}  // Modifiez ceci pour appeler directement handleFileChange
    />
                <label htmlFor="raised-button-file">
                    <Button 
                        variant="contained" 
                        component="span" 
                        color="primary" 
                        className={classes.buttonSpacing}  // Utilisez cette classe pour ajouter un espace à droite
                    >
                        Télécharger une photo
                    </Button>
                </label>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleUploadPhotos}
                >
                    Valider la photo
                </Button>
            </div>

      {/* Prévisualisation des photos */}
      <div className={classes.photoContainer}>
                {previewPhotos.map((photoURL, index) => (
                    <Card key={index} className={classes.photoCard}>
                        <CardMedia
                            className={classes.media}
                            image={photoURL}
                            title="Photo de prévisualisation"
                        />
                    </Card>
                ))}
      </div>



            
      <div className={classes.photoContainer}>
        {photos.map(photo => (
          <Card key={photo.id} className={classes.photoCard}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={`https://backend.csibon.app/photos/${photo.photo_url}`}
                title="Etablissement Photo"
              />
            </CardActionArea>
            <CardActions>
            <IconButton aria-label="delete" onClick={() => handleDeletePhoto(photo.id)}>
              <DeleteIcon />
            </IconButton>
              {/* Pour la modification (si nécessaire) */}
              <IconButton aria-label="edit" onClick={() => openEditDialog(photo.id)}>
              <EditIcon />
            </IconButton>
            </CardActions>
          </Card>
        ))}
      </div>


         {/* Boîte de dialogue pour la modification de la photo */}
    <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
      <DialogTitle>Modifier la photo</DialogTitle>
      <DialogContent>
  <input 
    type="file" 
    accept="image/*" 
    onChange={handleNewPhotoFileChange}
  />
</DialogContent>
      <DialogActions>
        <Button onClick={handleEditPhoto} color="primary">
          Modifier
        </Button>
        <Button onClick={() => setEditDialogOpen(false)} color="secondary">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>

    <div className={classes.root}>
  <Typography variant="h4" component="h1" className={classes.title}>
    Nombre de boissons offertes par utilisateur
  </Typography>
  <TextField
    value={offeredDrinks}
    onChange={(e) => handleOfferedDrinksChange(e.target.value)}
    label="Nombre de boissons offertes"
    type="number"
    variant="outlined"
    fullWidth
    className={classes.input}
  />
</div>


      <EstablishmentDescription description={establishment.description} onDescriptionChange={handleDescriptionChange} sethasAccess={sethasAccess} />
      <EstablishmentHours initialHours={establishment.horaire ? JSON.parse(establishment.horaire) : {}} onHoursChange={handleHoursChange} />

      {establishment && <EstablishmentSocialMedia
        socialMedia={establishment.socialMedia || {}}
        onSocialMediaChange={handleSocialMediaChange}
      />}




      {/* {establishment && <ProductManagement products={establishment.products} onProductsChange={handleProductsChange} />} */}
      <EstablishmentLocation location={{ lat: establishment.lat, lon: establishment.lon }} onLocationChange={handleLocationChange} />


    </div>
  );
}

export default AdminPage;
