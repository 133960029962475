import React, { useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';

function ThresholdSetter({ etabId, onThresholdUpdate }) {
    const [threshold, setThreshold] = useState('');

    const handleSetThreshold = () => {
        if (!threshold) {
            alert('Veuillez entrer une valeur de seuil valide.');
            return;
        }

        // axios.put(`https://backoffice.gourdefriendly.green/stock/inventory/threshold/${etabId}`, { threshold: threshold })
        //     .then(response => {
        //         onThresholdUpdate();  // Si vous souhaitez rafraîchir d'autres parties de l'UI
        //     })
        //     .catch(error => {
        //         console.error("Erreur lors de la mise à jour du seuil :", error);
        //     });

           axiosInstance.put(`https://backend.csibon.app/stock/inventory/threshold/${etabId}`, { threshold: threshold })
            .then(response => {
                onThresholdUpdate();  // Si vous souhaitez rafraîchir d'autres parties de l'UI
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour du seuil :", error);
            });
    };

    return (
        <div className="threshold-setter">
            {/* <h2>Modifier le seuil d'alerte</h2> */}
            <div>
                <label>Seuil :</label>
                <input type="number" value={threshold} onChange={e => setThreshold(e.target.value)} />
            </div>
            <button onClick={handleSetThreshold}>Mettre à jour le seuil</button>
        </div>
    );
}

export default ThresholdSetter;
