
import React, { useState  , useEffect} from 'react';
import axios from 'axios';  // Assurez-vous d'avoir installé axios
import styled from 'styled-components';
import axiosInstance from '../../../services/axiosInstance';

const FormWrapper = styled.div`
    width: 80%;
    margin: 2rem auto;
    background-color: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

const FormField = styled.div`
    margin-bottom: 1rem;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
`;

const SubmitButton = styled.button`
    background-color: #007BFF;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }
`;

const BrandImage = styled.img`
    max-width: 150px;   // Largeur maximale
    max-height: 150px;  // Hauteur maximale
    margin: 10px;       // Marge autour de l'image
    border-radius: 8px; // Coins arrondis pour une belle apparence
`;

const CreateBrandForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        image: ''
    });
    const [brands, setBrands] = useState([]);
    const [editingBrand, setEditingBrand] = useState(null);


    const startEditing = (brand) => {
        setFormData({
            name: brand.name,
            description: brand.description,
            image: brand.image
        });
        setEditingBrand(brand);
    }

    const handleImageChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            image: e.target.files[0]
        }));
    };
    
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = new FormData();
        dataToSend.append('name', formData.name);
        dataToSend.append('description', formData.description);
        if (formData.image) {
            dataToSend.append('image', formData.image);
        }
        
        // try {
        //     if (editingBrand) {
        //         const response = await axios.put(`https://backoffice.gourdefriendly.green/drinks/brands/${editingBrand.id}`, dataToSend);
        //         if (response.status === 200) {
        //             alert('Marque mise à jour avec succès!');
        //         }
        //     } else {
        //         try {
        //             const response = await axios.post('https://backoffice.gourdefriendly.green/drinks/brands', dataToSend);
        //             if (response.status === 201) {
        //                 alert('Marque créée avec succès!');
        //                 onClose(); // Ferme la fenêtre modale
        //             }
        //         } catch (error) {
        //             console.error("Erreur lors de la création de la marque", error);
        //             alert('Erreur lors de la création de la marque');
        //         }
        //     }
        // } catch (error) {
        //     console.error("Erreur lors de la mise à jour de la marque", error);
        //     alert('Erreur lors de la mise à jour de la marque');
        // }

        try {
            if (editingBrand) {
                const response = await axiosInstance.put(`/drinks/brands/${editingBrand.id}`, dataToSend);
                if (response.status === 200) {
                    alert('Marque mise à jour avec succès!');
                }
            } else {
                const response = await axiosInstance.post('/drinks/brands', dataToSend);
                if (response.status === 201) {
                    alert('Marque créée avec succès!');
                    onClose(); // Ferme la fenêtre modale
                }
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour ou de la création de la marque", error);
            alert('Erreur lors de la mise à jour ou de la création de la marque');
        }
    }
    

    // useEffect(() => {
    //     const fetchBrands = async () => {
    //         try {
    //             const response = await axios.get('https://backoffice.gourdefriendly.green/drinks/brands');
    //             setBrands(response.data);
    //         } catch (error) {
    //             console.error('Erreur lors de la récupération des marques', error);
    //         }
    //     };

    //     fetchBrands();
    // }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axiosInstance.get('/drinks/brands');
                setBrands(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des marques', error);
            }
        };
    
        fetchBrands();
    }, []);


    return (
        <FormWrapper>
            {editingBrand && <button onClick={() => setEditingBrand(null)}>Annuler la modification</button>}

            <Title>{editingBrand ? "Modifier la marque existante" : "Créer une nouvelle marque"}</Title>

            <form onSubmit={handleSubmit}>
                <FormField>
                    <Label>Nom:</Label>
                    <Input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </FormField>
                <FormField>
                    <Label>Description:</Label>
                    <Textarea name="description" value={formData.description} onChange={handleChange}></Textarea>
                </FormField>
                {/* <FormField>
                    <Label>Image (URL ou chemin):</Label>
                    <Input type="text" name="image" value={formData.image} onChange={handleChange} />
                </FormField> */}
                <FormField>
    <Label>Image :</Label>
    <Input type="file" name="image" onChange={handleImageChange} />
</FormField>

                <FormField>
                <SubmitButton type="submit">{editingBrand ? "Mettre à jour la marque" : "Ajouter la marque"}</SubmitButton>
                </FormField>
            </form>

            <h3>Marques ajoutées :</h3>
            <ul>
            {brands.map(brand => (
    <li key={brand.id}>
        <BrandImage src={`https://backend.csibon.app/static/drink_images/${brand.image_url}`} alt={brand.name} />

        {brand.name}
        <button onClick={() => startEditing(brand)}>Modifier</button>
    </li>
))}
            </ul>
        </FormWrapper>
    );
}


export default CreateBrandForm