import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button, Typography, makeStyles, Card, CardContent, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, IconButton , Snackbar, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { useParams } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '70vh', // Essayez avec une hauteur plus petite

        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        borderRadius: '10px',
        outline: 'none',
        boxShadow: theme.shadows[5],
        display: 'flex',
        flexDirection: 'column',

       
    },
    containerList: {
        display: 'flex',
        // overflowX: 'auto',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
       

        // overflowX: 'hidden',
    },

 

    containerCard: {
        flex: '0 0 auto',
        marginRight: theme.spacing(2),
        minWidth: 200,
        textAlign: 'center',
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    image: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing(1),
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    radioGroup: {
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    deleteButton: {
        marginTop: theme.spacing(1),
    },
    formControl: {
        width: '100%',
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },

    image: {
        width: '100%',  // Garde l'image réactive à la largeur de la carte
        height: 'auto', // Garde le ratio hauteur/largeur de l'image
        maxHeight: '150px', // Nouvelle limite de hauteur maximale pour l'image
        objectFit: 'cover', // Assure que l'image couvre la zone définie sans déformer l'aspect
        marginBottom: theme.spacing(1),
    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'column', // Affiche les éléments enfants en colonne
        alignItems: 'center', // Centre les boutons horizontalement
        gap: theme.spacing(1), // Espacement entre les boutons
    },

    modalCard: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto', // Ajustez en fonction du contenu
        maxWidth: '500px', // Limite de largeur
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
    modalHeader: {
        marginBottom: theme.spacing(2),
    },
    modalActions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    textField: {
        marginBottom: theme.spacing(1),
    },
    progressButton: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    textField: {
        marginTop: theme.spacing(2), // Ajoutez un espacement au-dessus du TextField
    },
    inputLabel: {
        fontSize: '1.2rem', // Ajustez la taille de la police du label
        marginTop: theme.spacing(-3), // Ajoutez un espacement au-dessus du label
    },
    inputRoot: {
        marginTop: theme.spacing(1), // Ajustez l'espacement entre le label et l'input
    },
}));

function CreateContainerComponent({ open, onClose, onAdd }) {
    const classes = useStyles();
    const [brand, setBrand] = useState('');
    const [name, setName] = useState('');
    const [volume, setVolume] = useState('');
    const [material, setMaterial] = useState('');
    const [image, setImage] = useState(null);
    const [containers, setContainers] = useState([]);
    const [containerType, setContainerType] = useState('gourde'); // 'gourde' ou 'mug'
    const { establishmentId } = useParams();
    const [initialStock, setInitialStock] = useState('');
    const [threshold, setThreshold] = useState('');


     // Nouveaux états pour le modal et la gestion du stock
     const [stockModalOpen, setStockModalOpen] = useState(false);
     const [selectedContainer, setSelectedContainer] = useState(null);
     const [stockChange, setStockChange] = useState(0);
     const [loading, setLoading] = useState(false);
     const [snackbarOpen, setSnackbarOpen] = useState(false);
     const [snackbarMessage, setSnackbarMessage] = useState('');
     const [price, setPrice] = useState('');


     // États pour l'édition du contenant
const [editMode, setEditMode] = useState(false);
const [editName, setEditName] = useState('');
const [editBrand, setEditBrand] = useState('');
const [editVolume, setEditVolume] = useState('');
const [editMaterial, setEditMaterial] = useState('');
const [editPrice, setEditPrice] = useState('');


// Fonction pour ouvrir le modal en mode édition
// Fonction pour ouvrir le modal en mode édition
const handleEditClick = (container) => {
    setSelectedContainer(container);
    setEditName(container.name || '');
    setEditBrand(container.brand || '');
    setEditVolume(container.volume || '');
    setEditMaterial(container.material || '');
    setEditPrice(container.price || '');
    setEditMode(true);
    setStockModalOpen(true);
};

// La fonction handleStockClick n'est plus nécessaire, car nous n'avons plus le bouton correspondant

// Fonction pour réinitialiser le mode édition
const resetEditMode = () => {
    setEditMode(false);
    setEditName('');
    setEditBrand('');
    setEditVolume('');
    setEditMaterial('');
    setEditPrice('');
};

// Fonction pour fermer le modal et réinitialiser
const handleCloseStockModal = () => {
    setStockModalOpen(false);
    setSelectedContainer(null);
    setStockChange(0);
    resetEditMode();
};

// Fonction pour mettre à jour les informations du contenant
const handleUpdateContainer = async () => {
    setLoading(true);
    try {
        const response = await fetch('https://backend.csibon.app/container_chr/inventory/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                container_id: selectedContainer.id,
                etablissement_id: establishmentId,
                update_container_info: true,
                name: editName,
                brand: editBrand,
                volume: editVolume,
                material: editMaterial,
                price: editPrice
            }),
        });

        if (!response.ok) {
            throw new Error('Erreur réseau lors de la mise à jour du contenant.');
        }

        const result = await response.json();
        
        // Mise à jour du contenant dans l'état local
        if (result.container) {
            setContainers(containers.map(container => 
                container.id === selectedContainer.id ? result.container : container
            ));
        }
        
        setSnackbarMessage(`Contenant mis à jour avec succès`);
        setSnackbarOpen(true);
    } catch (error) {
        console.error('Erreur lors de la mise à jour du contenant:', error);
        setSnackbarMessage(`Erreur lors de la mise à jour du contenant`);
        setSnackbarOpen(true);
    } finally {
        setLoading(false);
        handleCloseStockModal();
    }
};

     
    const navigate = useNavigate();
    

    useEffect(() => {
        const fetchContainers = async () => {
            console.log(`https://backoffice.gourdefriendly.green/container_chr/containers/establishment/${establishmentId}`)
            try {
               
                const response = await fetch(`https://backend.csibon.app/container_chr/containers/establishment/${establishmentId}`);
                if (!response.ok) {
                    throw new Error('Erreur réseau lors de la récupération des contenants.');
                }
    
                const containers = await response.json();
                setContainers(containers);
            } catch (error) {
                console.error('Erreur lors de la récupération des contenants:', error);
            }
        };
    
        fetchContainers();
    }, [establishmentId]); // Exécuter lorsque establishmentId change

    const handleSaveClick = async () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('brand', brand);
        formData.append('volume', volume);
        formData.append('material', material);
        formData.append('id_etablissement', establishmentId);
        formData.append('containerType', containerType);
        formData.append('initialStock', initialStock);
        formData.append('threshold', threshold);
        // In the handleSaveClick function, add this line:
        formData.append('price', price);
        if (image) {
            formData.append('file', image);
        }
    
        try {
            const response = await fetch('https://backend.csibon.app/container_chr/containers', { // Remplacez par l'URL de votre serveur Flask
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Erreur réseau lors de l’envoi du conteneur.');
            }
    
            const result = await response.json();
            console.log('Conteneur ajouté:', result);
    
            // Ajoutez le conteneur à l'état local si nécessaire
            setContainers([...containers, result.container]);
    
            // Réinitialiser les champs de formulaire
            setBrand('');
            setName('');
            setVolume('');
            setMaterial('');
            setPrice('');
            setImage(null);
            setContainerType('gourde');
        } catch (error) {
            console.error('Erreur lors de l’ajout du conteneur:', error);
        }
    };

    const handleDeleteClick = async (containerId) => {
        try {
            const response = await fetch(`https://backend.csibon.app/container_chr/containers/${containerId}`, {
                method: 'DELETE',
            });
    
            if (!response.ok) {
                throw new Error('Erreur réseau lors de la suppression du contenant.');
            }
    
            // Mettre à jour l'état pour supprimer le contenant de l'affichage local
            setContainers(containers.filter(container => container.id !== containerId));
    
            console.log('Conteneur supprimé avec succès');
        } catch (error) {
            console.error('Erreur lors de la suppression du contenant:', error);
        }
    };
    

    // Ouvrir le modal de gestion du stock
    const handleStockClick = (container) => {
        setSelectedContainer(container);
        setStockModalOpen(true);
    };

    // Fermer le modal de gestion du stock
    // const handleCloseStockModal = () => {
    //     setStockModalOpen(false);
    //     setSelectedContainer(null);
    //     setStockChange(0);
    // };

    const handleUpdateStock = async () => {
        // setLoading(true);
        // // Logique de mise à jour du stock (API call)
        // // Simulons un délai pour l'exemple

        // setTimeout(() => {
        //     setLoading(false);
        //     setSnackbarMessage(`Stock mis à jour pour ${selectedContainer.name}`);
        //     setSnackbarOpen(true);
        //     handleCloseStockModal();
        // }, 2000);


            setLoading(true);
            try {
                const response = await fetch('https://backend.csibon.app/container_chr/inventory/update', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        container_id: selectedContainer.id,
                        etablissement_id: establishmentId,
                        quantity_change: stockChange,
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Erreur réseau lors de la mise à jour du stock.');
                }
    
                const result = await response.json();
                console.log('Mise à jour du stock réussie:', result);
                setSnackbarMessage(`Stock mis à jour pour ${selectedContainer.name}`);
                setSnackbarOpen(true);
            } catch (error) {
                console.error('Erreur lors de la mise à jour du stock:', error);
                setSnackbarMessage(`Erreur lors de la mise à jour du stock.`);
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
                handleCloseStockModal();
            }
        
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    return (
        // <Modal open={open} onClose={onClose}>
        <>
        <AdminNavbar establishmentId={establishmentId} name={""} />
            <IconButton onClick={() => navigate(`/accueil`)} color="primary" aria-label="retour">
                <ArrowBackIcon />
            </IconButton>
            <div className={classes.modal}>
                {/* <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton> */}
                <Typography variant="h6" align="center" gutterBottom>
                    Gérer les contenants
                </Typography>
                <TextField
                    label="Nom"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />
                <TextField
                    label="Marque"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />
                <TextField
                    label="Volume"
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />
                <TextField
                    label="Matériau"
                    value={material}
                    onChange={(e) => setMaterial(e.target.value)}
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />
                <TextField
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />

<TextField
                    label="Stock initial"
                    value={initialStock}
                    onChange={(e) => setInitialStock(e.target.value)}
                    type="number"
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />
                <TextField
                    label="Seuil d'alerte"
                    value={threshold}
                    onChange={(e) => setThreshold(e.target.value)}
                    type="number"
                    fullWidth
                    margin="normal"
                    className={classes.input}
                />

                <TextField
                    label="Prix"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    fullWidth
                    margin="normal"
                    className={classes.input}
                    type="number"
                    InputProps={{
                    startAdornment: <span style={{ marginRight: 8 }}>€</span>,
                    }}
                />

                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Type de contenant</FormLabel>
                    <RadioGroup
                        row
                        aria-label="containerType"
                        name="containerType"
                        value={containerType}
                        onChange={(e) => setContainerType(e.target.value)}
                        className={classes.radioGroup}
                    >
                        <FormControlLabel value="gourde" control={<Radio />} label="Gourde" />
                        <FormControlLabel value="mug" control={<Radio />} label="Mug" />
                        <FormControlLabel value="box" control={<Radio />} label="Box" />

                    </RadioGroup>
                </FormControl>

                <Button variant="contained" color="primary" onClick={handleSaveClick} fullWidth>
                    Ajouter un contenant
                </Button>

           

                <div className={classes.containerList}>
                    {containers.map((container) => (
                        <Card key={container.id} className={classes.containerCard}>
                    
                            <CardContent>
                                <img src={"https://backend.csibon.app/static/container_images/"+container.image_url} alt={container.name} className={classes.image} />
                                <Typography variant="subtitle1">{container.name}</Typography>
                                <Typography variant="body2">Marque: {container.brand}</Typography>
                                <Typography variant="body2">Volume: {container.volume}</Typography>
                                <Typography variant="body2">Matériau: {container.material}</Typography>
                                <Typography variant="body2">Stock: {container.current_stock}</Typography>
                                <Typography variant="body2">Seuil {container.threshold}</Typography>
                                {/* <Typography variant="body2">Prix: {container.price ? `${container.price}€` : 'Non défini'}</Typography> */}
                                <Typography variant="body2">Prix: {container.price}</Typography>

                                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.deleteButton}
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteClick(container.id)}
                    >
                        Supprimer
                    </Button>

                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStockClick(container)}
                    >
                        Gérer le Stock
                    </Button> */}

<Button
        variant="contained"
        color="primary"
        onClick={() => handleEditClick(container)}
    >
        Modifier
    </Button>
                </div>
                
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>


             {/* Modal pour la gestion du stock */}
             {/* <Modal open={stockModalOpen} onClose={handleCloseStockModal}>
                <Card className={classes.modalCard}>
                    <CardContent>
                        <Typography variant="h6" className={classes.modalHeader}>
                            Gestion du Stock pour {selectedContainer?.name}
                        </Typography>
                        <TextField
                            label="Changer Stock (positif pour ajouter, négatif pour retirer)"
                            value={stockChange}
                            onChange={(e) => setStockChange(Number(e.target.value))}
                            type="number"
                            fullWidth
                            className={classes.textField}
                            InputLabelProps={{
                                className: classes.inputLabel
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.inputRoot,
                                },
                            }}
                        />
                        <div className={classes.modalActions}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpdateStock}
                                disabled={loading}
                                className={classes.progressButton}
                            >
                                {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : "Mettre à jour le Stock"}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Modal> */}

<Modal open={stockModalOpen} onClose={handleCloseStockModal}>
    <Card className={classes.modalCard}>
        <CardContent>
            <Typography variant="h6" className={classes.modalHeader}>
                Modification de {selectedContainer?.name}
            </Typography>
            
            {/* Formulaire d'édition des informations */}
            <TextField
                label="Nom"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                    className: classes.inputLabel
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
            
            <TextField
                label="Marque"
                value={editBrand}
                onChange={(e) => setEditBrand(e.target.value)}
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                    className: classes.inputLabel
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
            
            <TextField
                label="Volume"
                value={editVolume}
                onChange={(e) => setEditVolume(e.target.value)}
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                    className: classes.inputLabel
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
            
            <TextField
                label="Matériau"
                value={editMaterial}
                onChange={(e) => setEditMaterial(e.target.value)}
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                    className: classes.inputLabel
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
            
            <TextField
                label="Prix (€)"
                value={editPrice}
                onChange={(e) => setEditPrice(e.target.value)}
                type="number"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                    className: classes.inputLabel
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                    startAdornment: <span style={{ marginRight: 8 }}>€</span>,
                }}
            />
            
            {/* Séparateur */}
            <div style={{ margin: '16px 0', borderTop: '1px solid #e0e0e0' }} />
            
            {/* Section de gestion du stock */}
            <Typography variant="subtitle1" gutterBottom>
                Gestion du stock
            </Typography>
            
            <TextField
                label="Modifier le stock (positif pour ajouter, négatif pour retirer)"
                value={stockChange}
                onChange={(e) => setStockChange(Number(e.target.value))}
                type="number"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                    className: classes.inputLabel
                }}
                InputProps={{
                    classes: {
                        root: classes.inputRoot,
                    },
                }}
            />
            
            <div className={classes.modalActions}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        // Appel combiné pour mettre à jour à la fois les informations et le stock
                        setLoading(true);
                        
                        // Préparer les données
                        const updateData = {
                            container_id: selectedContainer.id,
                            etablissement_id: establishmentId,
                            update_container_info: true,
                            name: editName,
                            brand: editBrand,
                            volume: editVolume,
                            material: editMaterial,
                            price: editPrice
                        };
                        
                        // Ajouter la modification de stock si nécessaire
                        if (stockChange !== 0) {
                            updateData.quantity_change = stockChange;
                        }
                        
                        // Envoyer la requête
                        fetch('https://backend.csibon.app/container_chr/inventory/update', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(updateData),
                        })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Erreur réseau lors de la mise à jour');
                            }
                            return response.json();
                        })
                        .then(result => {
                            // Mise à jour du contenant dans l'état local
                            if (result.container) {
                                setContainers(containers.map(container => 
                                    container.id === selectedContainer.id ? result.container : container
                                ));
                            }
                            
                            setSnackbarMessage(`Modifications enregistrées avec succès`);
                            setSnackbarOpen(true);
                            handleCloseStockModal();
                        })
                        .catch(error => {
                            console.error('Erreur lors de la mise à jour:', error);
                            setSnackbarMessage(`Erreur lors de la mise à jour`);
                            setSnackbarOpen(true);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                    }}
                    disabled={loading}
                    className={classes.progressButton}
                >
                    {loading ? (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    ) : (
                        "Enregistrer toutes les modifications"
                    )}
                </Button>
            </div>
        </CardContent>
    </Card>
</Modal>

            {/* Snackbar pour les messages de confirmation */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />


            </>
        // </Modal>

        
    );
}

export default CreateContainerComponent;
