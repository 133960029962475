



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, Button, TextField, Typography, Paper, Container, IconButton, List, ListItem ,  } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import Navbar from './Navbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';

import CategorySelector from './CategorySelector'

const CategoryManager = () => {
  const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [showForm, setShowForm] = useState({});
    const [error, setError] = useState(null);

    // Charger les catégories au démarrage du composant
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://backend.csibon.app/categories_easy_drinks/categories');
            setCategories(response.data);
        } catch (error) {
            console.error("Erreur lors du chargement des catégories", error);
        }
    };

  
  const addCategory = async (path, name, selectedFile) => {

    const formData = new FormData();
    formData.append('name', name);
    if (selectedFile) {
        formData.append('image', selectedFile);
    }
    console.log('selectedFile : ' , selectedFile)

  
    let parentId = null;
    if (path.length > 0) {
        const parentCategory = getCategoryAtPath(categories, path);
        parentId = parentCategory ? parentCategory.id : null;
    
        if (parentId) {
                   formData.append('parent_id', parentId);
               }

    }
  
    console.log("parentId:", parentId); // Pour le débogage

    try {
        const response = await axios.post('https://backend.csibon.app/categories_easy_drinks/categories', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        setCategories(addToPath(categories, path, response.data));
    } catch (error) {
        console.error("Erreur lors de l'ajout d'une catégorie", error);
    }

    fetchCategories();
};
  
  
const updateCategory = async (path, name, selectedFile) => {
  const formData = new FormData();
  formData.append('name', name);
  if (selectedFile) {
      formData.append('image', selectedFile);
  }

  console.log('selectedFile : ' , selectedFile)

  const categoryId = getCategoryAtPath(categories, path).id;

  try {
      await axios.put(`https://backend.csibon.app/categories_easy_drinks/categories/${categoryId}`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });
      // setCategories(updateAtPath(categories, path,  response.data)); 
      fetchCategories()
  } catch (error) {
      console.error("Erreur lors de la mise à jour d'une catégorie", error);
  }
};


    const deleteCategory = async (path) => {
        const category = getCategoryAtPath(categories, path);
        if (category && category.subCategories.length > 0) {
            alert("Vous ne pouvez pas supprimer une catégorie qui contient des sous-catégories.");
            return;
        }
        try {
            await axios.delete(`https://backend.csibon.app/categories_easy_drinks/categories/${category.id}`);
            setCategories(deleteAtPath(categories, path));
        } catch (error) {
            console.error("Erreur lors de la suppression d'une catégorie", error);
        }
    };


    const toggleForm = (path, formType) => {
      const pathKey = `${formType}-${path.join("-")}`; // Clé unique pour chaque chemin et type de formulaire
      setShowForm({ ...showForm, [pathKey]: !showForm[pathKey] });
  };


   


  const deleteAtPath = (cats, path) => {
    if (path.length === 1) {
        return cats.filter((_, index) => index !== path[0]);
    } else {
        return cats.map((cat, index) => {
            if (index === path[0]) {
                return { ...cat, subCategories: deleteAtPath(cat.subCategories, path.slice(1)) };
            }
            return cat;
        });
    }
};




//     // Utilitaire pour ajouter une catégorie à un chemin spécifique
    const addToPath = (cats, path, newCat) => {
        if (path.length === 0) {
            return [...cats, newCat];
        } else {
            return cats.map((cat, index) => {
                if (index === path[0]) {
                    return { ...cat, subCategories: addToPath(cat.subCategories, path.slice(1), newCat) };
                }
                return cat;
            });
        }


    };


const getCategoryAtPath = (cats, path) => {
  let current = cats;
  for (let i = 0; i < path.length; i++) {
      if (i === path.length - 1) {
          return current[path[i]];
      }
      current = current[path[i]].subCategories;
  }
  return null; // Si le chemin est vide ou invalide
};



   // Utilitaire pour mettre à jour une catégorie à un chemin spécifique
   const updateAtPath = (cats, path, name, image) => {
    if (path.length === 0) {
        return [...cats];
    }

    return cats.map((cat, index) => {
        if (index === path[0]) {
            if (path.length === 1) {
                // Mise à jour de la catégorie actuelle
                return { ...cat, name, image };
            } else {
                // Mise à jour d'une sous-catégorie
                return { ...cat, subCategories: updateAtPath(cat.subCategories, path.slice(1), name, image) };
            }
        }
        return cat;
    });
};




  const style = {
    container: {
        margin: '20px auto',
        padding: '20px',
        maxWidth: '800px',
        backgroundColor: '#f7f7f7',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        borderRadius: '8px',
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        backgroundColor: '#ffffff',
        padding: '10px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '4px',
    },
    input: {
        flex: 1,
        marginRight: '10px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '16px',
    },
    button: {
        padding: '10px 15px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background 0.2s ease',
        '&:hover': {
            backgroundColor: '#45a049',
        },
    },
    category: {
      backgroundColor: '#ffffff',
      padding: '10px',
      marginBottom: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
  },
  subCategory: {
      backgroundColor: '#e0e0e0',
      padding: '10px',
      marginTop: '10px',
      marginLeft: '30px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      borderRadius: '4px',
  },

    image: {
        maxWidth: '100px',
        maxHeight: '100px',
        marginRight: '10px',
        borderRadius: '4px',
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    iconButton: {
        backgroundColor: 'transparent',
        color : 'black',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '5px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
};

const renderCategories = (cats, path = [], isSubCategory = false) => {
  return cats.map((cat, index) => {
      const currentPath = path.concat(index);
      const editFormKey = `edit-${currentPath.join("-")}`;
      const addFormKey = `add-${currentPath.join("-")}`;

      // Initialiser les clés dans `showForm` pour les nouvelles catégories
      if (showForm[editFormKey] === undefined) {
          setShowForm(prevState => ({ ...prevState, [editFormKey]: false }));
      }
      if (showForm[addFormKey] === undefined) {
          setShowForm(prevState => ({ ...prevState, [addFormKey]: false }));
      }

      return (
          <div key={index} style={isSubCategory ? style.subCategory : style.category}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <img src={cat.image} alt={cat.name} style={style.image} />
                  <h3 style={{ flexGrow: 1, margin: '0 10px' }}>{cat.name}</h3>
                  <div style={style.buttonGroup}>
                      <button style={style.iconButton} onClick={() => toggleForm(currentPath, 'edit')}>
                          {showForm[editFormKey] ? <CancelIcon /> : <EditIcon />}
                      </button>
                      <button style={style.iconButton} onClick={() => toggleForm(currentPath, 'add')}>
                          {showForm[addFormKey] ? <CancelIcon /> : <AddCircleOutlineIcon />}
                      </button>
                      <button style={style.iconButton} onClick={() => deleteCategory(currentPath)}>
                          <DeleteIcon />
                      </button>
                  </div>
              </div>
              {showForm[editFormKey] && (
                  <EditCategoryForm 
                      initialName={cat.name} 
                      initialImage={cat.image} 
                      onSubmit={(name, image) => {
                          updateCategory(currentPath, name, image);
                          toggleForm(currentPath, 'edit');
                      }} 
                  />
              )}
            {showForm[addFormKey] && (
  <AddCategoryForm 
      onSubmit={(name, image) => {
          addCategory(currentPath, name, image);
          toggleForm(currentPath, 'add');
      }}
      parentId={currentPath.length > 0 ? getCategoryAtPath(categories, currentPath).id : null}
  />
)}


              <div style={{ marginLeft: '20px' }}>
                  {renderCategories(cat.subCategories, currentPath, true)}
              </div>
          </div>
      );
  });
};

return (
  <>
   <Navbar  />
      <IconButton onClick={() => navigate(`/accueil`)} color="primary" aria-label="retour">
        <ArrowBackIcon />
      </IconButton>
      <div style={style.container}>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {renderCategories(categories)}
      <AddCategoryForm onSubmit={(name, image) => addCategory([], name, image)} />
    
  </div>
<center>
  </center>
      <CategorySelector/>
   
  </>

);

};


const EditCategoryForm = ({ initialName, initialImage, onSubmit }) => {
  const [name, setName] = useState(initialName);
    const [image, setImage] = useState(initialImage);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setName(initialName);
        setImage(initialImage);
    }, [initialName, initialImage]);
    
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
          setSelectedFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
              setImage(reader.result);
          };
          reader.readAsDataURL(file);
      }
  };

  const style = {
    form: {
        margin: '10px 0',
    },
    input: {
        margin: '5px',
        padding: '10px',
        width: 'calc(100% - 22px)',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },

  }

  return (
    <div style={style.form}>
        <input style={style.input} type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="New Name" />
        <input style={style.input} type="file" onChange={handleFileChange} />
        {selectedFile && <img src={image} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
        <button style={style.button} onClick={() => onSubmit(name, selectedFile)}>Modifier</button>
    </div>
);
};

const AddCategoryForm = ({ onSubmit ,  parentId = null}) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
          setSelectedFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
              setImage(reader.result);
          };
          reader.readAsDataURL(file);
      }
  };

  const handleSubmit = () => {
    onSubmit(name, selectedFile); // Envoi du nom et du fichier sélectionné
    setName('');
    setSelectedFile(null);
};

    const style = {
      form: {
          margin: '10px 0',
      },
      input: {
          margin: '5px',
          padding: '10px',
          width: 'calc(100% - 22px)',
      },
      button: {
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
      },
  };

  return (

    <div style={style.form}>
        <input style={style.input} type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nom de la catégorie" />
        <input style={style.input} type="file" onChange={handleFileChange} />
        {selectedFile && <img src={image} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
        <button style={style.button} onClick={handleSubmit}>Ajouter</button>
    </div>
);
};

export default CategoryManager;
